import React, { useState, useEffect } from "react";
import "../../../assets/css/formList.css";
import { Spin, Button, Modal } from "antd";
import axios from "axios";
import { FormNotification } from "../../utils/Message";
import { css } from "emotion";
import jwt from 'jsonwebtoken'

const FormList = () => {
  const [select, setSelect] = useState("");
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState([]);
  const {role} = jwt.decode(localStorage.getItem("token"))

  useEffect(() => {
    if (select) {
      requestFormData();
    } else setFetching(false);
  }, [select]);

  const onChange = (e) => {
    setSelect(e.target.value);
  };
  const requestFormData = () => {
    const token = localStorage.getItem("token");
    setFetching(true);
    axios
      .get(`${process.env.REACT_APP_SERVICE_API}/api/${select}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        setData(data);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        FormNotification("Unable to retrieve data", "error");
      });
  };
  const onDelete = (formType, formId) => {
    const token = localStorage.getItem("token");
    axios
      .delete(
        `${process.env.REACT_APP_SERVICE_API}/api/${formType.slice(
          0,
          formType.length - 1
        )}/${formId.split("-")[2]}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        requestFormData();
      })
      .catch((error) => {
        FormNotification("Unable to delete form!", "error");
      });
  };

  if (fetching) {
    return <Spin className="spinner" size="large" />;
  }

  return (
    <div className="dashboard">
      <div className="select-form">
        <p>Please select the forms you would like to view</p>
        <select name="form-options" id="" onChange={onChange}>
          <option value="">Select...</option>
          <option value="applications">Home Loan Application</option>
          <option value="lenders">Lender Application</option>
          <option value="sponsors">Sponsor Application</option>
          {role === "admin" && <option value="volunteers">Volunteer Application</option>}
          <option value="surveys">Be Known Survey</option>
          <option value="administrative/assist-disclaimers">
            Assist Disclaimer Forms
          </option>
        </select>
      </div>

      <div className="forms-list">
        {data.length > 0 ? (
          <div className="item-container">
            <div className="row">
              <div className="row-header col">Name</div>
              <div className="row-header col">ID</div>
              <div className="row-header col">Date Submitted</div>
              <div className="row-header col">Delete</div>
            </div>
            {data.map(({ firstName, lastName, id, createdAt }) => (
              <div className="row form-item" key={id}>
                <div className="col">
                  <a href={`/pdf/applications/${id}`} target="_blank">
                    {firstName}, {lastName}
                  </a>
                </div>
                <div className="col">{id}</div>
                <div className="col">{createdAt.split("T")[0]}</div>
                <div className="col">
                  <Button
                    onClick={() =>
                      Modal.confirm({
                        icon: (
                          <li className={`fa fa-exclamation-circle ${styles}`}>
                            <span>Confirm</span>
                          </li>
                        ),
                        content:
                          "The form will be deleted and you will not be able to undo this action. Are you sure you want to delete this form?",
                        okText: "Delete",
                        cancelText: "Cancel",
                        onOk: () => onDelete(select, id),
                        style: {
                          position: "relative",
                          top: "250px",
                        },
                      })
                    }
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-forms">
            <p>No form data available!</p>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = css`
  & {
    font-size: 30px !important;
    span {
      margin-left: 10px;
      font-size: 24px !important;
    }
    .ant-btn {
      color: black;
    }
  }
`;
export default FormList;
