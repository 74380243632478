import React from "react";
import { css } from "emotion";

const Intro = ({ header, text }) => {
  return (
    <div className={styles}>
      <section className={styles}>
        <div className="intro">
          <h1 className="intro-header">{header}</h1>
          <p>{text}</p>
        </div>
      </section>
    </div>
  );
};

const styles = css`
  & {
    margin: 0 auto;

    section {
      padding-top: 60px;
      max-width: 70%;
    }
    .intro {
      text-align: center;
    }
    .intro h1,
    .intro p {
      color: #6d6d6d;
      font-weight: 500;
    }

    .intro p {
      max-width: 980px;
      margin: 0 auto;
      text-align: center;
    }

    .intro-header {
      text-align: center;
      font-size: 40px;
      font-weight: bold !important;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 927px) {
      .intro-header {
        font-size: 1.7em;
      }
      .intro p {
        font-size: 0.9em;
      }
    }
  }
`;

export default Intro;
