import React, { useEffect } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import {
  objectEmpty,
  requiredString,
  phone,
  dobLimit,
  renderStates,
} from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../../utils/Debugger";
import FormError from "../../utils/FormError";
import { css } from "emotion";

const VolunteerApplication = ({
  disableBtn,
  initialValues,
  setInitialValues,
}) => {
  const onChange = (e) => {
    const text = e.target.value.replace(/(\n)/g, '. ')
    setInitialValues({
      ...initialValues,
      [e.target.name]: text,
    });
  };

  const validationSchema = Yup.object({
    firstName: requiredString,
    lastName: requiredString,
    dob: Yup.date().required("Required"),
    phone: phone,
    email: Yup.string().email().required("Required"),
    address: Yup.string().required("Required"),
    address2: Yup.string(),
    city: requiredString,
    state: requiredString,
    zip: Yup.number()
      .required("Required")
      .typeError("Must be a number without any special characters"),
    skills: Yup.string().required("Required"),
    assistArea: Yup.string().required("Required"),
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      <div className={`${styles} container`}>
        <p>
          Thank you for considering to volunteer to help the Muslim community
          become independent
        </p>
        {/* <Debugger data={initialValues} /> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form onChange={onChange}>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="firstName">First Name</label>
                <Field
                  className="form-control"
                  name="firstName"
                  placeholder="First Name"
                />
                <ErrorMessage component={FormError} name="firstName" />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="lastName">Last Name</label>
                <Field
                  className="form-control"
                  name="lastName"
                  placeholder="Last Name"
                />
                <ErrorMessage component={FormError} name="lastName" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="dob">Date of Birth</label>
                <Field
                  type="date"
                  className="form-control"
                  name="dob"
                  placeholder="DOB"
                  max={dobLimit()}
                />
                <ErrorMessage component={FormError} name="dob" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-lg-6">
                <label htmlFor="address">Street Address</label>
                <Field
                  className="form-control"
                  name="address"
                  placeholder="1234 Main St"
                />
                <ErrorMessage component={FormError} name="address" />
              </div>

              <div className="form-group col-lg-4">
                <label htmlFor="address2">Suite, Apartment, Unit, etc.</label>
                <Field
                  className="form-control"
                  name="address2"
                  placeholder="Apartment, studio, or floor"
                />
                <ErrorMessage component={FormError} name="address2" />
              </div>

              <div className="form-group col-lg-4">
                <label htmlFor="city">City</label>
                <Field className="form-control" name="city" />
                <ErrorMessage component={FormError} name="city" />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="state">State</label>
                <Field name="state" className="custom-select" as="select">
                  <option value="">Select...</option>
                  {renderStates()}
                </Field>
                <ErrorMessage component={FormError} name="state" />
              </div>

              <div className="form-group col-lg-2 col-md-4">
                <label htmlFor="zip">Zip</label>
                <Field className="form-control" name="zip" />
                <ErrorMessage component={FormError} name="zip" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="phone">Phone Number:</label>
                <Field type="tel" className="form-control" name="phone" />
                <ErrorMessage component={FormError} name="phone" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="email">Email:</label>
                <Field
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="example@email.com"
                />
                <ErrorMessage component={FormError} name="email" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="email">Skills</label>
                <Field
                  type="skills"
                  className="form-control"
                  name="skills"
                  as="textarea"
                />
                <ErrorMessage component={FormError} name="skills" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="assistArea">
                  Areas where you believe you can assist:
                </label>
                <Field
                  type="assistArea"
                  className="form-control"
                  name="assistArea"
                  as="textarea"
                />
                <ErrorMessage component={FormError} name="assistArea" />
              </div>
            </div>
            <Context />
          </Form>
        </Formik>
      </div>
    </>
  );
};

const styles = css`
  & {
    button {
      margin: 15px 0;
    }
    @media screen and (max-width: 635px) {
      max-width: 95% !important;
      p,
      label {
        font-size: 0.9em;
      }
    }
  }
`;

export default VolunteerApplication;
