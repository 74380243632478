import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  HomeView,
  AboutView,
  ProcessView,
  ContactView,
  VolunteerView,
  SponsorView,
  LenderView,
  // SingleApplicationView,
  VolunteerApplicationView,
  EmbeddedPaymentView,
  BeKnownSurveyView,
  SponsorApplicationView,
  LenderApplicationView,
  LoanApplicationView
} from "./components/views/Views";
import { Message } from "./components/utils/Message";

function App() {
  return (
    <Router>
      <Switch> 
        {/* PUBLIC ROUTES*/}
        <Route exact path="/" component={HomeView} />
        <Route exact path="/about" component={AboutView} />
        <Route exact path="/process" component={ProcessView} />
        <Route exact path="/contact" component={ContactView} />
        <Route exact path="/volunteer" component={VolunteerView} />
        <Route exact path="/sponsor" component={SponsorView} />
        <Route exact path="/lender" component={LenderView} />
        <Route exact path="/contribute" component={EmbeddedPaymentView} />

        {/* APPLICATION ROUTES*/}
        <Route
          exact
          path="/forms/loan-application"
          component={LoanApplicationView}
        />
        <Route
          exact
          path="/forms/be-known-survey"
          component={BeKnownSurveyView}
        />
        <Route
          exact
          path="/forms/sponsor-application"
          component={SponsorApplicationView}
        />
        <Route
          exact
          path="/forms/lender-application"
          component={LenderApplicationView}
        />
        <Route
          exact
          path="/forms/volunteer-application"
          component={VolunteerApplicationView} 
        />

        {/* 404 ROUTE*/}
        <Route
          path="*"
          component={() => (
            <Message>
              <p>404 Page Not Found!</p>
            </Message>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
