import React, { useState, useEffect } from "react";
import axios from "axios";
import { FormNotification } from "../utils/Message";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import { css } from "emotion";


const Contact = () => {
  const [submitted, setSubmitted] = useState(false);

  const Context = () => {
    const { errors, values, validateForm } = useFormikContext();

    useEffect(() => {
      validateForm();
    }, [errors, values]);
    return null;
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    subject: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    message: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    validationSchema
      .validate(values, { abortEarly: false })
      .then(() => axios.post("/api/contact", values))
      .then(() => {
        setSubmitted(true);
        resetForm({});
      })
      .catch((error) => {
        FormNotification(
          "There was a problem sending your message. Try again later.",
          "error"
        );
        resetForm({});
      });
  };

  return (
    <div className="single-page contact-page">
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Contact</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-page-wrap">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="entry-content">
                <h2>Get In touch</h2>
                <p>
                  Have questions in mind or want to get involved? Feel free to
                  contact us!
                </p>

                <ul className="contact-social d-flex flex-wrap align-items-center">
                  <li>
                    <a href="https://www.facebook.com/muslimsforchange.mfc/">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/muslims_for_change/?hl=en">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/muslimsforchange/">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>

                <ul className="contact-info p-0">
                  <li>
                    <i className="fa fa-phone"></i>
                    <span type="tel:4049103713">(404) 910-3713</span>
                  </li>
                  <li>
                    <i className="fa fa-envelope"></i>
                    <span type="email">info@muslimscanchange.com</span>
                  </li>
                  {/* <li><i className="fa fa-map-marker"></i><span>Main Str. no 45-46, b3, 56832, Los Angeles, CA</span></li> */}
                </ul>
              </div>
            </div>

            <div className="col-12 col-lg-7">
              <Formik
                initialValues={{
                  name: "",
                  subject: "",
                  email: "",
                  message: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                <Form className="contact-form">
                  {submitted && (
                    <div className="submitted-message">
                      <p>
                        Thank you for contacting us, we will contact you as soon
                        as possible!
                      </p>
                    </div>
                  )}
                  <div className="form-inputs">
                    <div className={`form-row ${style}`}>
                      <div className="form-group col-12">
                        <Field
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Name"
                        />
                        <ErrorMessage id="error" component="div" name="name" />
                      </div>
                    </div>

                    <div className={`form-row ${style}`}>
                      <div className="form-group col-12">
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Subject"
                          name="subject"
                        />
                        <ErrorMessage
                          id="error"
                          component="div"
                          name="subject"
                        />
                      </div>
                    </div>

                    <div className={`form-row ${style}`}>
                      <div className="form-group col-12">
                        <Field
                          type="text"
                          className="form-control"
                          name="email"
                          placeholder="Email"
                        />
                        <ErrorMessage id="error" component="div" name="email" />
                      </div>
                    </div>

                    <div className={`form-row ${style}`}>
                      <div className="form-group col-12">
                        <Field
                          type="text"
                          as="textarea"
                          className="form-control"
                          name="message"
                          placeholder="Message"
                          rows="8"
                          cols="6"
                        />
                        <ErrorMessage
                          id="error"
                          component="div"
                          name="message"
                        />
                      </div>
                    </div>
                  </div>
                  <span>
                    <button type="submit" className="btn gradient-bg">
                      Contact Us
                    </button>
                  </span>
                  <Context />
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const style = css`
  & {
    #error {
      color: white;
      font-size: 12px;
    }
  }
`;
export default Contact;
