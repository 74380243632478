import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import axios from "axios";
import { lenderValues } from "../../consts/formValues";
import "../../../assets/css/forms.css";
import { Message, FormNotification } from "../../utils/Message";
import { css } from "emotion";
import LenderApplicationExceptions from "./LenderApplicationExceptions";
import LenderApplication from "./LenderApplication";
import LenderApplicationSignatures from "./LenderApplicationSignatures";


const LenderFormView = () => {
  const [initialValues, setInitialValues] = useState(lenderValues);
  const [current, setCurrent] = useState(0);
  const [disableBtn, setDisabledBtn] = useState(false);
  const [submitted, setSubmitted] = useState({ success: false, error: false });

  useEffect(() => {
    submitted.error && FormNotification(null, "error");
  }, [submitted]);

  const onSubmit = (e) => {
    setSubmitted({ success: false, error: false });
    let data = initialValues;
    delete data.initialAgreement;
    delete data.loanAgreementCheck;

    axios
      .post("/api/lender", data)
      .then((res) => setSubmitted({ success: true, error: false }))
      .catch((error) => setSubmitted({ success: false, error: true }));
  };

  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  const steps = [
    {
      title: "Lender Exceptions",
      content: (
        <LenderApplicationExceptions
          disableBtn={setDisabledBtn}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
        />
      ),
    },
    {
      title: "Lender Application",
      content: (
        <LenderApplication
          disableBtn={setDisabledBtn}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
        />
      ),
    },
    {
      title: "Lender Signature",
      content: (
        <LenderApplicationSignatures
          disableBtn={setDisabledBtn}
          initialValues={initialValues}
          setInitialValues={setInitialValues}
        />
      ),
    },
  ];

  if (submitted.success) {
    return (
      <Message>
        <h4>Your application has been submitted successfully!</h4>
        <p>
          You will receive an email with any additional information we may need
          after your application has been reviewed. Please keep in mind that the
          application revision process may take some time. Thank you.
        </p>
      </Message>
    );
  }

  return (
    <div className="container forms">
      <div className="steps-content">
        <h3 className="content-header">{steps[current].title}</h3>
        {steps[current].content}
      </div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button
            type="primary"
            style={{ marginRight: "8px" }}
            disabled={disableBtn}
            onClick={() => next()}
          >
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            style={{ marginRight: "8px" }}
            disabled={disableBtn}
            onClick={() => onSubmit()}
          >
            Submit
          </Button>
        )}
        {current > 0 && (
          <Button style={{ marginRight: "8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        <Button
          onClick={() =>
            Modal.confirm({
              icon: (
                <li className={`fa fa-exclamation-circle ${styles}`}>
                  <span>Confirm</span>
                </li>
              ),
              content:
                "The form data will not be saved. Are you sure you want to exit?",
              okText: "Exit",
              cancelText: "Stay",
              onOk: () => (window.location = "/"),
            })
          }
        >
          Exit
        </Button>
      </div>
    </div>
  );
};

const styles = css`
  & {
    font-size: 30px !important;
    span {
      margin-left: 10px;
      font-size: 24px !important;
    }
  }
`;

export default LenderFormView;
