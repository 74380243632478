import React from "react";
import logo from "../../assets/images/logo.png";
import { message } from "antd";
import "../../assets/css/message.css";

export const Message = ({ children }) => {
  return (
    <div className="container">
      <div className="general-message">
        <img className="d-block" src={logo} alt="logo" />
        {children}
        <p>
          Go to <a href="/">Home Page</a>
        </p>
      </div>
    </div>
  );
};

export const FormNotification = (text, type) => {
  type === "error"
    ? message.error(
        text ||
          "There was an issue submitting your application. Please try again later."
      )
    : message.success(text);
};
