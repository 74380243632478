import React from "react";
import Home from "./Home";
import About from "./About";
import Process from "./Process";
import Contact from "./Contact";
import Volunteer from "./Volunteer";
import Sponsor from "./Sponsor";
import Lender from "./Lender";
// import SingleApplication from "./SingleApplication";
import LoanFormView from "../forms/loanApplication/LoanFormView";
import BeKnownView from "../forms/beKnownSurvey/BeKnownView";
import SponsorApplication from "../forms/SponsorApplication";
import LenderFormView from "../forms/lenderApplication/LenderFormView";
import VolunteerFormView from "../forms/volunteerApplication/VolunteerFormView";
import asyncComponent from "../../DynamicImport";
import { Helmet } from "react-helmet";

const EmbbededPayment = asyncComponent(() =>
  import("../utils/EmbbededPayment")
);
const MainLayout = asyncComponent(() => import("./MainLayout"));

export const HomeView = ({ location }) => {
  const { pathname } = location;
  return (
    <>
      <MainLayout pathname={pathname} content={() => <Home />} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Muslims For Change - Home Page</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Muslims For Change is a 501(c)3 organization created to make a difference in the lives of Muslims both as individuals and a collective. The name speaks for itself, but we believe that with the help of every individual, we can make huge strides towards improving the Muslim community. Let us start our journey together for a well rounded Muslim community."
        />
        <meta property="og:title" content="Muslims For Change" />
        <meta
          property="og:description"
          content="Muslims For Change is a 501(c)3 organization created to make a difference in the lives of Muslims both as individuals and a collective. The name speaks for itself, but we believe that with the help of every individual, we can make huge strides towards improving the Muslim community. Let us start our journey together for a well rounded Muslim community."
        />
        <meta property="og:url" content="https://muslimsforchange.org/" />
        <meta property="og:site_name" content="Muslims For Change" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://muslimsforchange.org/#website",
                "url": "https://muslimsforchange.org/",
                "name": "Muslims For Change",
                "description": "Muslim Interest Free Loans",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://muslimsforchange.org/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://muslimsforchange.org/#webpage",
                "url": "https://muslimsforchange.org/",
                "name": "Home Page | Helping Fellow Muslims | Muslim Interest Free Loans",
                "isPartOf": {
                  "@id": "https://muslimsforchange.org/#website"
                },
                "primaryImageOfPage": {
                  "@id": "https://muslimsforchange.org/#primaryimage"
                },
                "datePublished": "2020-07-20T00:00:00+04:00",
                "dateModified": "2020-07-28T00:00:00+04:00",
                "description": "We help fellow Jews in the Atlanta area maintain financial stability in challenging times with our interest-free loans. Find out how to apply today.",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://muslimsforchange.org/"
                    ]
                  }
                ]
              }
            ]
          }`}
        </script>
      </Helmet>
    </>
  );
};
export const AboutView = ({ location }) => {
  const { pathname } = location;
  return (
    <>
      <MainLayout pathname={pathname} content={() => <About />} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Muslims For Change - About Page</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="MFC's vision is to empower every Muslims to become independent. That is why we do not have investors or evaluate individuals with credit scores. We help Muslims gain; independent, healthy, and successful lives by providing them with long-lasting interest-free housing, resources and support, training programs to improve their lives."
        />
        <meta property="og:title" content="Muslims For Change - About Page" />
        <meta
          property="og:description"
          content="MFC's vision is to empower every Muslims to become independent. That is why we do not have investors or evaluate individuals with credit scores. We help Muslims gain; independent, healthy, and successful lives by providing them with long-lasting interest-free housing, resources and support, training programs to improve their lives."
        />
        <meta property="og:url" content="https://muslimsforchange.org/about" />
        <meta property="og:site_name" content="Muslims For Change" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://muslimsforchange.org/#website",
                "url": "https://muslimsforchange.org/",
                "name": "Muslims For Change",
                "description": "Muslim Interest Free Loans",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://muslimsforchange.org/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://muslimsforchange.org/about/#webpage",
                "url": "https://muslimsforchange.org/about/",
                "name": "About Muslims For Change | 501c3 Qualified Non-Profit | Muslim Interest Free Loans of Atlanta",
                "isPartOf": {
                  "@id": "https://muslimsforchange.org/#website"
                },
                "primaryImageOfPage": {
                  "@id": "https://muslimsforchange.org/about/#primaryimage"
                },
                "datePublished": "2020-07-20T00:00:00+04:00",
                "dateModified": "2020-07-28T00:00:00+04:00",
                "description": "Muslims For Change provides interest free loans to Muslim Atlanta community members facing financially challenging situations. Learn more about Muslims For Change by clicking here.",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://muslimsforchange.org/about/"
                    ]
                  }
                ]
              }
            ]
          }`}
        </script>
      </Helmet>
    </>
  );
};
export const ProcessView = ({ location }) => {
  const { pathname } = location;
  return (
    <>
      <MainLayout pathname={pathname} content={() => <Process />} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Muslims For Change - Application Process</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Through the help of Allah and the Muslim community, MFC can achieve its goals in improving the life of every Muslim, one individual, and one family at a time."
        />
        <meta
          property="og:title"
          content="Muslims For Change - Application Process"
        />
        <meta
          property="og:description"
          content="Through the help of Allah and the Muslim community, MFC can achieve its goals in improving the life of every Muslim, one individual, and one family at a time."
        />
        <meta
          property="og:url"
          content="https://muslimsforchange.org/process"
        />
        <meta property="og:site_name" content="Muslims For Change" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://muslimsforchange.org/#website",
                "url": "https://muslimsforchange.org/",
                "name": "Muslims For Change",
                "description": "Muslim Interest Free Loans",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://muslimsforchange.org/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://muslimsforchange.org/process/#webpage",
                "url": "https://muslimsforchange.org/process/",
                "name": "Application Process | How To Apply | Muslim Interest Free Loans",
                "isPartOf": {
                  "@id": "https://muslimsforchange.org/#website"
                },
                "datePublished": "2020-07-20T00:00:00+04:00",
                "dateModified": "2020-07-28T00:00:00+04:00",
                "description": "Read about our Muslims For Change process to find out if your elible for a Muslim Interest Free Loan and if this loan right for you. Learn more about how to apply here.",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://muslimsforchange.org/process/"
                    ]
                  }
                ]
              }
            ]
          }`}
        </script>
      </Helmet>
    </>
  );
};
export const ContactView = ({ location }) => {
  const { pathname } = location;
  return (
    <>
      <MainLayout pathname={pathname} content={() => <Contact />} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Muslims For Change - Contact Page</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Have questions in mind or want to get involved? Feel free to contact us! We hope to be a positive impact in the lives of every Muslim and to spread our influence to change the world."
        />
        <meta property="og:title" content="Muslims For Change - Contact Page" />
        <meta
          property="og:description"
          content="Have questions in mind or want to get involved? Feel free to contact us! We hope to be a positive impact in the lives of every Muslim and to spread our influence to change the world."
        />
        <meta
          property="og:url"
          content="https://muslimsforchange.org/contact"
        />
        <meta property="og:site_name" content="Muslims For Change" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://muslimsforchange.org/#website",
                "url": "https://muslimsforchange.org/",
                "name": "Muslims For Change",
                "description": "Muslim Interest Free Loans",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://muslimsforchange.org/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://muslimsforchange.org/contact/#webpage",
                "url": "https://muslimsforchange.org/contact/",
                "name": "Contact - Muslims For Change",
                "isPartOf": {
                  "@id": "https://muslimsforchange.org/#website"
                },
                "datePublished": "2020-07-20T00:00:00+04:00",
                "dateModified": "2020-07-28T00:00:00+04:00",
                "description": "We want to be in touch with you! Enter your contact information and let us know how you want to get involved with us.",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://muslimsforchange.org/contact/"
                    ]
                  }
                ]
              }
            ]
          }`}
        </script>
      </Helmet>
    </>
  );
};

export const VolunteerView = ({ location }) => {
  const { pathname } = location;
  return (
    <>
      <MainLayout pathname={pathname} content={() => <Volunteer />} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Muslims For Change - Volunteer Page</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Our volunteers and supporters, provide support and care for underprivileged families in Atlanta, GA, and elsewhere in the United States."
        />
        <meta
          property="og:title"
          content="Muslims For Change - Volunteer Page"
        />
        <meta
          property="og:description"
          content="Our volunteers and supporters, provide support and care for underprivileged families in Atlanta, GA, and elsewhere in the United States."
        />
        <meta
          property="og:url"
          content="https://muslimsforchange.org/volunteer"
        />
        <meta property="og:site_name" content="Muslims For Change" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "WebSite",
                "@id": "https://muslimsforchange.org/#website",
                "url": "https://muslimsforchange.org/",
                "name": "Muslims For Change",
                "description": "Muslim Interest Free Loans",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://muslimsforchange.org/?s={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }
                ],
                "inLanguage": "en-US"
              },
              {
                "@type": "WebPage",
                "@id": "https://muslimsforchange.org/volunteer/#webpage",
                "url": "https://muslimsforchange.org/volunteer/",
                "name": "Application volunteer | How To Apply | Muslim Interest Free Loans",
                "isPartOf": {
                  "@id": "https://muslimsforchange.org/#website"
                },
                "datePublished": "2020-07-20T00:00:00+04:00",
                "dateModified": "2020-07-28T00:00:00+04:00",
                "description": "Read about our Muslims For Change volunteer to find out if your elible for a Muslim Interest Free Loan and if this loan right for you. Learn more about how to apply here.",
                "inLanguage": "en-US",
                "potentialAction": [
                  {
                    "@type": "ReadAction",
                    "target": [
                      "https://muslimsforchange.org/volunteer/"
                    ]
                  }
                ]
              }
            ]
          }`}
        </script>
      </Helmet>
    </>
  );
};

export const LenderView = (props) => {
  return (
    <>
      <MainLayout content={() => <Lender />} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Muslims For Change - Lender Page</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Empower Muslims to be independent in every aspect of their lives. Qard hassan is a loan extended mainly for welfare purposes. The borrower needs to only pay back the amount borrowed, with no interest."
        />
        <meta property="og:title" content="Muslims For Change - Lender Page" />
        <meta
          property="og:description"
          content="Empower Muslims to be independent in every aspect of their lives. Qard hassan is a loan extended mainly for welfare purposes. The borrower needs to only pay back the amount borrowed, with no interest."
        />
        <meta property="og:url" content="https://muslimsforchange.org/lender" />
        <meta property="og:site_name" content="Muslims For Change" />
      </Helmet>
    </>
  );
};
export const SponsorView = (props) => {
  return (
    <>
      <MainLayout content={() => <Sponsor />} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Muslims For Change - Sponsor Page</title>
        <meta name="robots" content="index, follow" />
        <meta
          Name="description"
          content="Working with MFC is not only good for our beneficiaries, it’s good for your business. You can transform your business while having a positive impact."
        />
        <meta property="og:title" content="Muslims For Change - Sponsor Page" />
        <meta
          property="og:description"
          content="Working with MFC is not only good for our beneficiaries, it’s good for your business. You can transform your business while having a positive impact."
        />
        <meta
          property="og:url"
          content="https://muslimsforchange.org/sponsor"
        />
        <meta property="og:site_name" content="Muslims For Change" />
      </Helmet>
    </>
  );
};
export const LoanApplicationView = (props) => {
  return (
    <>
      <MainLayout content={() => <LoanFormView />} displayFooter={false} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>MFC Home Loan Application</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Through the help of Allah and the Muslim community, MFC can achieve its goals in improving the life of every Muslim, one individual, and one family at a time."
        />
        <meta property="og:title" content="MFC Home Loan Application" />
        <meta
          property="og:description"
          content="Through the help of Allah and the Muslim community, MFC can achieve its goals in improving the life of every Muslim, one individual, and one family at a time."
        />
        <meta
          property="og:url"
          content="https://muslimsforchange.org/forms/loan-application"
        />
        <meta property="og:site_name" content="Muslims For Change" />
      </Helmet>
    </>
  );
};
export const VolunteerApplicationView = (props) => {
  return (
    <>
      <MainLayout content={() => <VolunteerFormView />} displayFooter={false} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>MFC Volunteer Application</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Thank you for considering to become a part of MFC and your willingness to help bring change to the Muslim community living in the United States by volunteering your time, knowledge, skills, and much more. May Allah reward you for your intentions and make you of those who uplift their community!"
        />
        <meta property="og:title" content="MFC Volunteer Application" />
        <meta
          property="og:description"
          content="Thank you for considering to become a part of MFC and your willingness to help bring change to the Muslim community living in the United States by volunteering your time, knowledge, skills, and much more. May Allah reward you for your intentions and make you of those who uplift their community!"
        />
        <meta
          property="og:url"
          content="https://muslimsforchange.org/forms/volunteer-application"
        />
        <meta property="og:site_name" content="Muslims For Change" />
      </Helmet>
    </>
  );
};

export const BeKnownSurveyView = (props) => {
  return (
    <>
      <MainLayout content={() => <BeKnownView />} displayFooter={false} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>MFC Be Known Survey</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Take a five minute survey and be known in the muslim community. We would to like account for every Muslim living in the United States."
        />
        <meta property="og:title" content="MFC Be Known Survey" />
        <meta
          property="og:description"
          content="Take a five minute survey and be known in the muslim community. We would to like account for every Muslim living in the United States."
        />
        <meta
          property="og:url"
          content="https://muslimsforchange.org/forms/be-known-survey"
        />
        <meta property="og:site_name" content="Muslims For Change" />
      </Helmet>
    </>
  );
};

export const SponsorApplicationView = (props) => {
  return (
    <>
      <MainLayout
        content={() => <SponsorApplication />}
        displayFooter={false}
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>MFC Sponsor Application</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Thank you for considering to become part of MFC and your willingness to help bring change to the Muslim community living in the United States. Working with MFC is not only good for our beneficiaries, it’s good for your business. You can transform your business while having a positive impact."
        />
        <meta property="og:title" content="MFC Sponsor Application" />
        <meta
          property="og:description"
          content="Thank you for considering to become part of MFC and your willingness to help bring change to the Muslim community living in the United States. Working with MFC is not only good for our beneficiaries, it’s good for your business. You can transform your business while having a positive impact."
        />
        <meta
          property="og:url"
          content="https://muslimsforchange.org/forms/sponsor-application"
        />
        <meta property="og:site_name" content="Muslims For Change" />
      </Helmet>
    </>
  );
};

export const LenderApplicationView = (props) => {
  return (
    <>
      <MainLayout content={() => <LenderFormView />} displayFooter={false} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>MFC Lender Application</title>
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Thank you for considering to become part of MFC and your willingness to help expedite the transformation process to bring change to the Muslim community living in the United States. The more united and committed we are, the faster and more we can accomplish!"
        />
        <meta property="og:title" content="MFC Lender Application" />
        <meta
          property="og:description"
          content="Thank you for considering to become part of MFC and your willingness to help expedite the transformation process to bring change to the Muslim community living in the United States. The more united and committed we are, the faster and more we can accomplish!"
        />
        <meta
          property="og:url"
          content="https://muslimsforchange.org/forms/lender-application"
        />
        <meta property="og:site_name" content="Muslims For Change" />
      </Helmet>
    </>
  );
};

// export const SingleApplicationView = ({ match }) => {
//   return (
//     <>
//       <MainLayout
//         content={() => <SingleApplication paramsId={match.params.id} />}
//         displayFooter={false}
//       />
//       <Helmet>
//         <meta charSet="utf-8" />
//         <title>Muslims For Change</title>
//         <meta name="robots" content="noindex, nofollow" />
//       </Helmet>
//     </>
//   );
// };




export const EmbeddedPaymentView = ({ location, match }) => <EmbbededPayment />;
