import "../../../assets/css/singleApplication.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { unNestObject, formatPhone } from "../../utils/helpers";
import {
  Page,
  Text,
  Document,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import FieldGroup from "../../utils/FieldGroup";

const SingleApplicationPDF = ({ initialValues, formId }) => {
  const [dataRequest, setDataRequest] = useState({
    fetching: true,
    error: false,
  });

  const [data, setData] = useState(
    (initialValues && unNestObject(initialValues)) || {}
  );

  const { fetching, error } = dataRequest;

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_SERVICE_API}/api/lender/${formId}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        if (!data) {
          setDataRequest({ fetching: false, error: true });
        } else {
          setDataRequest({ fetching: false, error: false });
          setData(data);
        }
      })
      .catch((error) => {
        setDataRequest({ fetching: false, error: true });
      });
  }, []);

  const {
    id,
    firstName,
    lastName,
    address,
    address2,
    city,
    state,
    zip,
    phone,
    email,
    lendingAmount,
    willingToWait,
    agreeDate,
    signatureName,
  } = data;

  if (fetching) {
    return <Spin size="large" className="spinner" />;
  } else if (error) {
    return (
      <div className="fetchError">
        <Text>
          Unable to retrieve application data. Please try again at a later time!
        </Text>
      </div>
    );
  }

  return (
    <div className="application application-container">
      <PDFViewer width="100%" height="700px">
        <Document>
          <Page size="A4" style={styles.view}>
            <Text style={styles.title}>Lender Application</Text>

            <section>
              <Text break style={styles.subtitle}>
                Lender Information: {id}
              </Text>
              <FieldGroup values={[{ name: "First Name", value: firstName }]} />

              <FieldGroup values={[{ name: "Last Name", value: lastName }]} />

              <FieldGroup
                values={[{ name: "Street Address", value: address }]}
              />

              <FieldGroup
                values={[
                  { name: "Suite, Apt, Unit.", value: address2 || "" },
                  { name: "City", value: city },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "State", value: state },
                  { name: "Zip", value: zip },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Phone", value: formatPhone(phone) },
                  { name: "Email", value: email },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Lending Amount", value: lendingAmount },
                  { name: "Willing to wait", value: willingToWait },
                ]}
              />
            </section>

            <View style={styles.agreeSignatureView}>
              <Text style={styles.subtitle}>Lender Agreement</Text>

              <Text style={styles.text}>
                Muslims For Change Inc. (MFC) is Borrowing from the Lender an
                interest free loan in the sum of the Loan Amount. If the loan is
                approved, MFC agrees to fully, absolutely and unconditionally
                guarantee full payment of the Loan Amount as fast as MFC can.
                The creditor also agrees that MFC will aim to pay it’s dept in
                less than 6 year, but when it can’t they are not to be pressured
                to pay full amount, but rather both the lender and MFC should
                work together to create a full repayment plan.
              </Text>

              <Text style={styles.text}>
                MFC by signing this Loan Agreement is thereby liable for payment
                of the Loan Amount. This means that MFC is liable for the full
                amount and, as such, could be called upon to repay the total
                balance due provided that lender gives notice 6 months ahead of
                time.
              </Text>

              <Text style={styles.text}>
                MFC recognize that this Loan Agreement creates a binding
                obligation on the organization until the Loan Amount is fully
                paid. MFC also understands that typical loan repayment terms for
                MFC are 30 to 72 months depending on the size of the loan,
                beginning from one month after MFC receives the loan and signs
                the loan Promissory Note. The actual repayment terms for MFC may
                vary somewhat from these typical terms.
              </Text>

              <Text style={styles.text}>
                The lender understands and agrees that any and all disputes
                which occur as a result of this loan and which are unable to be
                resolved between the parties directly shall be subject to
                binding arbitration by an MFC appointed Qadi (Judge). Should any
                party fail to abide by the rulings of the Qadi (Judge), the
                other party may pursue enforcement of the ruling to the degree
                allowed by the Qadi (Judge) or MFC’s Sharia authorities. All
                parties to any loan and or agreement agree to be bound by any
                and all rulings of the appointed Qadi (Judge).
              </Text>

              <Text style={styles.text}>
                All matters affecting the interpretation of this Agreement and
                the rights of the parties hereto shall be governed by the laws
                of the State the agreement took place.
              </Text>
            </View>
            <View style={styles.agreeSignatureView}>
              <Text break style={styles.subtitle}>
                Signatures
              </Text>
              <FieldGroup
                values={[
                  { name: "Lender's Full Name:", value: signatureName },
                  { name: "Date", value: agreeDate },
                ]}
              />
            </View>
          </Page>

          <Page size="A4" style={styles.view}>
            <Text style={styles.title}>MFC Official Signature</Text>
            <View style={styles.agreeSignatureView}>
              <Text style={styles.text}>
                Received By: ______________________
              </Text>

              <Text style={styles.text}>Comments:</Text>
            </View>

            <View style={styles.officialSignatureView}>
              <FieldGroup
                values={[
                  { name: "Official's Signature:", value: "" },
                  { name: "Date", value: "" },
                ]}
              />
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

const styles = StyleSheet.create({
  view: {
    color: "rgb(80, 79, 79)",
    fontFamily: "Times-Roman",
    paddingBottom: "20px",
    paddingTop: "30px",
  },
  title: {
    fontSize: 20,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    margin: "20px 0",
    textAlign: "center",
  },
  text: {
    fontSize: 12,
    margin: "5px 0",
  },
  agreeSignatureView: {
    margin: "30px 40px",
  },
  officialSignatureView: {
    margin: "600px 40px 0",
  }
});
export default SingleApplicationPDF;
