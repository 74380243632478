import React from "react";
import LoanApplicationExceptions from "../forms/loanApplication/LoanApplicationExceptions";
import ApplicantInfo from "../forms/loanApplication/ApplicantInfo";
import CoApplicantInfo from "../forms/loanApplication/CoApplicantInfo";
import EmergencyAndReferences from "../forms/loanApplication/EmergencyAndReferences";
import ApplicantEmployment from "../forms/loanApplication/ApplicantEmployment";
import CoApplicantEmployment from "../forms/loanApplication/CoApplicantEmployment";
import LoanRequest from "../forms/loanApplication/LoanRequest";
import MonthlyExpenses from "../forms/loanApplication/MonthlyExpenses";
import LoanApplicationSignatures from "../forms/loanApplication/LoanApplicationSignatures";
import SingleApplication from "../views/SingleApplication";
import * as Yup from "yup";
import { states } from "../consts/states";
import ApplicantStatus from "../forms/loanApplication/ApplicantStatus";
import { Field, ErrorMessage } from "formik";
import FormError from "./FormError";
import CoApplicantExpenses from "../forms/loanApplication/CoApplicantExpenses";

export const renderStates = () => {
  return states.map(({ name, abbreviation }) => {
    return (
      <option key={name} value={abbreviation}>
        {name}
      </option>
    );
  });
};

export const objectEmpty = (obj) => Object.keys(obj).length === 0;

export const dobLimit = () => {
  const date = new Date();
  const dd = date.getDate().toString();
  const mm = date.getUTCMonth() + 1;
  const yy = date.getUTCFullYear() - 18;
  return `${yy}-0${mm}-${dd}`;
};

export const renderFromSections = (
  setBtnDisabled,
  initialValues,
  setInitialValues
) => {
  const sections = [
    { title: "Loan Criteria", content: LoanApplicationExceptions },
    { title: "Applicant Information", content: ApplicantInfo },
    { title: "Applicant Status", content: ApplicantStatus },
    { title: "Co-Applicant Information", content: CoApplicantInfo },
    {
      title: "Emergency Contact and Reference",
      content: EmergencyAndReferences,
    },
    { title: "Employment Information", content: ApplicantEmployment },
    {
      title: "Co-Applicant Employment Information",
      content: CoApplicantEmployment,
    },
    { title: "Loan Request", content: LoanRequest },
    { title: "Monthly Expenses", content: MonthlyExpenses },
    { title: "Co-Applicant Monthly Expenses", content: CoApplicantExpenses },
    {
      title: "Signatures",
      content: LoanApplicationSignatures,
      signatureOrder: 1,
    },
    {
      title: "Loan Agreement",
      content: LoanApplicationSignatures,
      signatureOrder: 2,
    },
    { title: "Review Application", content: SingleApplication },
  ];

  return sections.map(({ title, content: Content, signatureOrder }) => ({
    title,
    content: (
      <Content
        disableBtn={setBtnDisabled}
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        signatureOrder={signatureOrder}
      />
    ),
  }));
};

export const { requiredString, phone, expenseValue, selectRequired } = {
  selectRequired: Yup.string().required("Required"),
  requiredString: Yup.string()
    .matches(/^[A-Z ]+$/i, "Must not include numbers or special characters.")
    .required("Required"),
  phone: Yup.number()
    .required("Required")
    .test(
      "len",
      "Must be exactly 10 digits",
      (val) => val && val.toString().length === 10
    )
    .typeError("Must not include any special characters"),
  expenseValue: Yup.number()
    .required("Required")
    .typeError("Must be a number, e.g 100"),
};

export const unNestObject = (values) => {
  let data = {};
  for (const [key, value] of Object.entries(values)) {
    data = { ...data, ...value };
  }

  return data;
};

export const formatPhone = (phone) => {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const populateHousehold = (value, setHouseholdFields) => {
  let fieldArr = [];
  const field = (i) => (
    <div key={i} className="form-row">
      <div className="form-group col-md-4 col-lg-2">
        <label htmlFor={`householdAge${i}`}>Age</label>
        <Field className="form-control" name={`householdAge${i}`} />
        <ErrorMessage component={FormError} name={`householdAge${i}`} />
      </div>

      <div className="form-group col-md-4 col-lg-2">
        <label htmlFor={`householdGender${i}`}>Gender</label>
        <Field
          className="custom-select"
          name={`householdGender${i}`}
          as="select"
        >
          <option value="">Select...</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </Field>
        <ErrorMessage component={FormError} name={`householdGender${i}`} />
      </div>
    </div>
  );

  for (let i = 1; i <= value && i <= 10; i++) {
    fieldArr.push(field(i));
  }

  setHouseholdFields(fieldArr);
};
