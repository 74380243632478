import React, { useEffect } from "react";
import { Form, Field, Formik, useFormikContext, ErrorMessage } from "formik";
// import Debugger from "../../utils/Debugger";
import { objectEmpty, requiredString, dobLimit } from "../../utils/helpers";
import * as Yup from "yup";
import FormError from "../../utils/FormError";
import { css } from "emotion";

const VolunteerApplicationSignatures = ({
  disableBtn,
  initialValues,
  setInitialValues,
}) => {
  const onChange = (e) => {
    let data;
    if (e.target.name === "loanAgreementCheck") {
      data = {
        [e.target.name]: e.target.checked,
      };
    } else {
      data = {
        [e.target.name]: e.target.value,
      };
    }

    setInitialValues({
      ...initialValues,
      ...data,
    });
  };

  const validationSchema = Yup.object({
    signatureName: requiredString,
    agreeDate: Yup.date().required("Required"),
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !initialValues.loanAgreementCheck || !objectEmpty(errors)
          ? disableBtn(true)
          : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      {/* <Debugger data={initialValues} /> */}
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        <Form onChange={onChange}>
          <div className={`container ${styles}`}>
            <div className="agreement-content">
              <div>
                <h5>Introduction</h5>
                <p>
                  Muslims For Change, through its work, its supporters,
                  employees and volunteers wishes to be a role model of good
                  conduct and excellence in the community and the sector.
                </p>

                <h5>Purpose</h5>
                <p>
                  By complying with the Code of Conduct, MFC can ensure that
                  the highest standard of conduct is consistently maintained by
                  its volunteers.
                </p>

                <h5>Principles</h5>
                <p>
                  MFC expects volunteers to understand and comply with its
                  various Policies and Procedures, Code of Conduct and with U.S.
                  laws.
                </p>

                <div className="agreement-item">
                  <h5>Respect</h5>
                  <ol>
                    <li>
                      To always treat fellow volunteers, staff and supporters
                      with respect and dignity.
                    </li>
                    <li>
                      To be mindful and respect the views and beliefs of others.
                    </li>
                    <li>
                      To follow reasonable instructions from your supervisor.
                    </li>
                    <li>
                      To ensure personal appearance and hygiene levels are well
                      maintained.
                    </li>
                    <li>
                      To dress modestly and be mindful of your conduct in gender
                      interaction.
                    </li>
                    <li>
                      Not to engage in any dishonest, fraudulent or criminal
                      activities.
                    </li>
                    <li>
                      Not to behave in a way that is deemed to be offensive,
                      intimidating humiliating or threatened.
                    </li>
                  </ol>
                </div>

                <div className="agreement-item">
                  <h5>Fairness</h5>
                  <ol start="8">
                    <li>
                      To report any unlawful actions by fellow volunteers, staff
                      and supporters.
                    </li>
                    <li>To be righteous and truthful at all times.</li>
                    <li>
                      To treat people fair and equal and not to discriminate.
                    </li>
                    <li>Not to be bias and unjust in your conduct.</li>
                    <li>
                      Not to misuse MFC’s property or use it for personal
                      gains.
                    </li>
                    <li>
                      Not to allow other business to conflict with your role.
                    </li>
                  </ol>
                </div>

                <div className="agreement-item">
                  <h5>Leadership</h5>
                  <ol start="14">
                    <li>
                      To act as a role model, have a positive attitude and
                      always lead by example.
                    </li>
                    <li>
                      To honour your commitment and carry out roles to the best
                      of your ability.
                    </li>
                    <li>
                      To perform your duties with honesty, integrity and with
                      professionalism.
                    </li>
                    <li>
                      To show appropriate behaviour towards fellow volunteers,
                      staff and supporters.
                    </li>
                    <li>To share ideas, experiences and skills.</li>
                  </ol>
                </div>

                <div className="agreement-item">
                  <h5>Protection</h5>
                  <ol start="19">
                    <li>
                      To safeguard the reputation of Muslims For Change at all times
                    </li>
                    <li>
                      To perform roles responsibly and maintain a safe and
                      secure working environment.
                    </li>
                    <li>
                      To honour and abide by MFC’s policies.
                    </li>
                    <li>
                      To maintain high level of confidentiality at all times.
                    </li>
                  </ol>
                </div>

                <div className="agreement-item">
                  <h5>Explained</h5>
                  <ol>
                    <li>
                      Everybody has equal worth as a human being and therefore
                      volunteers should allow everyone to maintain their dignity
                      and treat people with respect. Volunteers should always
                      conduct themselves in a way in which they would like to be
                      treated.
                    </li>
                    <li>
                      Volunteers should be mindful about the different beliefs
                      of individuals and groups. Belief may reflect numerous
                      areas and therefore you should always be open and
                      respectful. Volunteers should avoid forcing their opinion
                      or beliefs upon others.
                    </li>
                    <li>
                      Our staff and volunteers are the backbone of the
                      organisation. In order to maintain efficiency and
                      productivity as well as a good working environment, MFC
                      requests volunteers to liaise with and observe
                      instructions from their supervisor(s).
                    </li>
                    <li>
                      Failure to keep up a standard of hygiene can have many
                      implications. Not only is there an increased risk of
                      getting an infection or illness, but there are many social
                      and psychological aspects that can be affected. Being
                      presentable is a reflection of personality,
                      professionalism and an outlook of oneself.
                    </li>
                    <li>
                      When deciding the level of ‘modesty’ volunteers should be
                      aware about the environment and surrounding they are in.
                      Volunteers should be mindful and respectful on the
                      different views and practices of gender segregation.
                    </li>
                    <li>
                      Volunteers must not conduct themselves in a way that
                      undermines MFC morals and values such as dishonesty and
                      fraudulent acts. MFC is a politically neutral
                      organisation, therefore volunteers should not engage in
                      any negative political or threatening activities that may
                      be detrimental to Muslims For Change.
                    </li>
                    <li>
                      MFC aims to allow all its staff and volunteers to
                      flourish in a safe working and learning environment
                      without the fear of being intimidated, bullied and
                      harassed. Everyone in the organisation including
                      volunteers has therefore the responsibility to ensure that
                      their actions, intentionally or unintentionally, does not
                      constitute or harassment or ill treatment.
                    </li>
                    <li>
                      MFC expects volunteers to follow reasonable instructions
                      and guidelines, any breaches of organisation policies and
                      unlawful acts should be reported regardless of it being
                      from an employee, a supporter or a fellow volunteer.
                    </li>
                    <li>
                      Being trustworthy and truthful is one of the best traits
                      to possess. It is a positive reflection of oneself. Being
                      truthful means being righteous.
                    </li>
                    <li>
                      MFC expects volunteers to conduct themselves in the best
                      manner possible. Volunteers should always practice
                      fairness and be mindful, and respect people’s views,
                      background, gender, age, ability and beliefs.
                    </li>
                    <li>
                      It is important that decisions are made on the basis of
                      avoiding external and internal scrutiny and to also
                      maintain and uphold MFC’s values. Volunteers should never
                      be bias or unjust in their conduct and dealings.
                    </li>
                    <li>
                      MFC receives funds from public supporters and private
                      sector organisations; we therefore use these funds to buy
                      equipment and other resources. Volunteers should not
                      misuse MFC’s property nor use it for personal gains as
                      the resources and facilities are very limited.
                    </li>
                    <li>
                      MFC’s mission and values should not be compromised by
                      ‘other business’ interests volunteers may have. MFC
                      requests volunteers to be fully committed to their time
                      with MFC and to separate personal interests when engaging.
                    </li>
                    <li>
                      The community, MFC employees, volunteers and supporters
                      should look up to volunteers as a good example. Volunteers
                      should always thrive to maintain good standards of conduct
                      at all times.
                    </li>
                    <li>
                      MFC asks volunteers to demonstrate commitment when
                      engaging. Volunteers are the driving force behind MFC and
                      therefore they are working towards uplifting the Muslim community.
                    </li>
                    <li>
                      MFC seeks to ensure that the work environment for its
                      employees and volunteers is supportive and one where
                      respect is shown amongst peers, to its supporters and the
                      community. Volunteers should always uphold integrity and
                      always have a professional approach in their dealings.
                    </li>
                    <li>
                      MFC aims to sustain a healthy relationship with
                      individuals both within and outside of the organisation.
                      MFC therefore expects staff and volunteers to behave in a
                      professional manner within the working environment.
                    </li>
                    <li>
                      MFC takes pride in working with volunteers from all
                      backgrounds, experiences and professions. MFC encourages
                      volunteers to work together and where appropriate share
                      relevant experiences and skills.
                    </li>
                    <li>
                      Volunteers should always be mindful in their conduct and
                      always protect the reputation of MFC, its staff and
                      volunteers, employees and supporters.
                    </li>
                    <li>
                      MFC requires its volunteers to perform their
                      responsibilities adequately and safely to maintain
                      consistency and efficiency.MFC is liable in maintaining a working 
                      environment where all activities can be carried out with the highest
                      consideration for the health and safety of both volunteers
                      and staff members.
                    </li>
                    <li>
                      The highest form of standards of behaviour is expected in
                      all areas of the organisation’s life. MFC expects
                      volunteers to maintain and sustain MFC's policies and U.S.laws.
                    </li>
                    <li>
                      Although MFC operates in an open fashion, there may be
                      times where a volunteer acquires or becomes aware of
                      confidential information whether it is about another
                      volunteer, staff member or the organisation. Volunteers
                      must be aware of the need to keep such matters
                      confidential.
                    </li>
                  </ol>
                </div>

                <div className="agreement-item">
                  <h5>Policy on Political Activities &amp; Terrorism</h5>
                  <p>
                    Muslims For Change is a 501(c)3 non-profit charitable organisation and,
                    as such, it is prohibited by its corporate character to
                    participate in any type of political activities or in any
                    non-charitable functions which may conflict with its
                    obligation under Internal Revenue Code. These prohibitions apply
                    equally to all persons who work for and or represent Muslims 
                    For Change in any capacity.
                  </p>
                  <p>
                    In addition, MFC volunteers should not (unless authorised in
                    writing) render an opinion or make a statement to the media
                    or in any social gathering, unless he/she prefaces that such
                    statements or opinions are solely his/hers and they do not
                    reflect the opinions or position of Muslims For Change.
                  </p>

                  <span>I agree to the following policy:</span>
                  <ol>
                    <li>
                      As a volunteer of Muslims For Change, I will not engage myself
                      in any actions or activities which may be detrimental to
                      the organisation’s continued good standing within the
                      legal/social community at large.
                    </li>
                    <li>
                      I agree to refrain from any and all activities which may
                      conflict with Muslims For Change’s obligations, purpose and
                      goals. Furthermore, I agree in the event that I may be
                      called upon to render an opinion or make statements to the
                      media or in any social gathering, to preface that such
                      statements or opinions are solely mine and that they do
                      not reflect the opinions or position of Muslims For Change.
                    </li>
                    <li>
                      I irrevocably covenant, promise and agree to indemnify
                      Muslims For Change and hold it harmless from and against any
                      and all losses, claims, expenses, demands, damages, or
                      liabilities, of whatever kind or nature which Muslims
                      For Change may sustain or to which it may become subject
                      arising out of or relating in any way to my participation
                      in any activities in conflict with Muslims For Change’s
                      obligation under Internal Revenue Code, including in 
                      defending against or enforcing any such losses, claims, 
                      expenses, suits, damages, or liabilities.
                    </li>
                    <li>
                      This document is executed by me knowingly, intelligently
                      and voluntary.
                    </li>
                  </ol>
                </div>

                <div>
                  <h5>Confidentiality</h5>
                  <p>
                    The Volunteer shall not make use of, divulge or communicate
                    to any person any trade secrets or other confidential
                    information of or relating to Muslims For Change, or that of
                    other persons or bodies with whom MFC has dealings of any
                    sort, which the ‘Volunteer’ may have received or obtained,
                    or has otherwise been acquired by them in confidence, whilst
                    engaging for MFC.
                  </p>

                  <p>
                    All material, whether held on paper, electronically or
                    magnetically which was made or acquired by the ‘Volunteer’
                    during the course of engagement, belongs to Muslims For Change 
                    and are not to be shared with any person without the permission of MFC.
                  </p>

                  <h5>Raising Concerns</h5>
                  <p>
                    For further information or to raise concerns please email
                    info@msulimsforchange.org
                  </p>

                  <p>
                    <i>
                      The document outlines reasonable expectations of
                      volunteers and is not legally binding nor is it a contract
                      of employment, so therefore the applicant has the right to
                      refuse.
                    </i>
                  </p>
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="signatureName">Applicant Full Name</label>
                <Field
                  className="form-control"
                  name="signatureName"
                  placeholder="Applicant Name"
                />
                <ErrorMessage component={FormError} name="signatureName" />
              </div>

              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="agreeDate">Date</label>
                <Field type="date" className="form-control" name="agreeDate" />
                <ErrorMessage component={FormError} name="agreeDate" />
              </div>
            </div>

            <div className="form-check">
              <Field
                name="loanAgreementCheck"
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="loanAgreementCheck">
                I have read this Agreement and I understand and fully agree to
                it.
              </label>
            </div>
          </div>
          <Context />
        </Form>
      </Formik>
    </>
  );
};

const styles = css`
  & {
    .agreement-item h5 {
      text-align: center;
    }

    .agreement-content {
      margin-bottom: 60px;
    }
    h5, span {
      margin: 30px 0 20px;
    }

    p,
    li {
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

export default VolunteerApplicationSignatures;
