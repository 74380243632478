import "../../../assets/css/singleApplication.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { unNestObject, formatPhone } from "../../utils/helpers";
import {
  Page,
  Text,
  Document,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import FieldGroup from "../../utils/FieldGroup";

const SingleApplicationPDF = ({ initialValues, formId }) => {
  const [dataRequest, setDataRequest] = useState({
    fetching: true,
    error: false,
  });

  const [data, setData] = useState(
    (initialValues && unNestObject(initialValues)) || {}
  );

  const { fetching, error } = dataRequest;

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_SERVICE_API}/api/sponsor/${formId}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        if (!data) {
          setDataRequest({ fetching: false, error: true });
        } else {
          setDataRequest({ fetching: false, error: false });
          setData(data);
        }
      })
      .catch((error) => {
        setDataRequest({ fetching: false, error: true });
      });
  }, []);

  const {
    id,
    firstName,
    lastName,
    businessName,
    address,
    address2,
    city,
    state,
    zip,
    phone,
    email,
    grossSalary,
  } = data;
  //REMOVE
  if (fetching) {
    return <Spin size="large" className="spinner" />;
  } else if (error) {
    return (
      <div className="fetchError">
        <Text>
          Unable to retrieve application data. Please try again at a later time!
        </Text>
      </div>
    );
  }

  return (
    <div className="application application-container">
      <PDFViewer width="100%" height="700px">
        <Document>
          <Page size="A4" style={styles.view}>
            <Text style={styles.title}>Sponsor Application</Text>

            <section>
              <Text break style={styles.subtitle}>
                Sponsor Information: {id}
              </Text>
              <FieldGroup values={[{ name: "First Name", value: firstName }]} />

              <FieldGroup values={[{ name: "Last Name", value: lastName }]} />

              <FieldGroup
                values={[{ name: "Business Name", value: businessName }]}
              />

              <FieldGroup
                values={[{ name: "Street Address", value: address }]}
              />

              <FieldGroup
                values={[
                  { name: "Suite, Apt, Unit.", value: address2 || "" },
                  { name: "City", value: city },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "State", value: state },
                  { name: "Zip", value: zip },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Phone", value: formatPhone(phone) },
                  { name: "Email", value: email },
                ]}
              />

              <FieldGroup
                values={[
                  {
                    name: "Gross Annual Income",
                    value: `$${grossSalary}` || "",
                  },
                ]}
              />
            </section>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

const styles = StyleSheet.create({
  view: {
    color: "rgb(80, 79, 79)",
    fontFamily: "Times-Roman",
    paddingBottom: "20px",
    paddingTop: "30px",
  },
  title: {
    fontSize: 20,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    margin: "20px 0",
    textAlign: "center",
  },
});
export default SingleApplicationPDF;
