import React, { useState, useEffect } from "react";
import { Button, Progress, Modal } from "antd";
import { renderFromSections, unNestObject } from "../../utils/helpers";
import axios from "axios";
import {
  agreement,
  applicantInfoValues,
  applicantStatus,
  coApplicantInfoValues,
  emergencyReferencesValues,
  employmentInfoValues,
  coEmploymentInfoValues,
  loanInfoValues,
  applicantExpenseValues,
  coApplicantExpenseValues,
  loanSignatureValues,
} from "../../consts/formValues";
import "../../../assets/css/forms.css";
import { Message, FormNotification } from "../../utils/Message";
import { css } from "emotion";

const LoanFormView = () => {
  const [initialValues, setInitialValues] = useState({
    agreement,
    applicantInfoValues,
    applicantStatus,
    coApplicantInfoValues,
    emergencyReferencesValues,
    employmentInfoValues,
    coEmploymentInfoValues,
    loanInfoValues,
    applicantExpenseValues,
    coApplicantExpenseValues,
    loanSignatureValues,
  });
  const { applicantStatus: APPLICANT } = initialValues;
  const [current, setCurrent] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [submitted, setSubmitted] = useState({ success: false, error: false });
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    setProgress(current * 10);
  }, [current]);

  useEffect(() => {
    submitted.error && FormNotification(null, "error");
  }, [submitted]);

  const steps = renderFromSections(
    setBtnDisabled,
    initialValues,
    setInitialValues
  );

  const onSubmit = async () => {
    setSubmitted({ success: false, error: false });

    const { data: ip } = await axios.get("https://api.ipify.org");
    const data = {
      ...unNestObject(initialValues),
      ip,
    };

    try {
      await axios.post("/api/application", data);
      setSubmitted({ success: true, error: false });
    } catch (error) {
      setSubmitted({ success: false, error: true });
    }
  };

  const next = () => {
    if (APPLICANT.hasCoApplicant === "No") {
      current === 2 || current === 5 || current === 8
        ? setCurrent(current + 2)
        : setCurrent(current + 1);
    } else if (
      APPLICANT.hasCoApplicant === "Yes" &&
      APPLICANT.coApplicantLivesTogether == "Yes"
    ) {
      current === 8 ? setCurrent(current + 2) : setCurrent(current + 1);
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    if (APPLICANT.hasCoApplicant === "No") {
      current === 4 || current === 7 || current === 10
        ? setCurrent(current - 2)
        : setCurrent(current - 1);
    } else if (
      APPLICANT.hasCoApplicant === "Yes" &&
      APPLICANT.coApplicantLivesTogether == "Yes"
    ) {
      current === 10 ? setCurrent(current - 2) : setCurrent(current - 1);
    } else {
      setCurrent(current - 1);
    }
  };

  if (submitted.success) {
    return (
      <Message>
        <h4>Your application has been submitted successfully!</h4>
        <p>
          You will receive an email with any additional information we may need
          after your application has been reviewed. Please keep in mind that the
          application revision process may take some time. Thank you.
        </p>
      </Message>
    );
  }

  return (
    <div className="container forms">
      <div className="steps-content">
        <Progress percent={progress} />

        <h3 className="content-header">{steps[current].title}</h3>
        {steps[current].content}
      </div>
      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button
            type="primary"
            style={{ marginRight: "8px" }}
            disabled={btnDisabled}
            onClick={() => next()}
          >
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            style={{ marginRight: "8px" }}
            disabled={btnDisabled}
            onClick={() => onSubmit()}
          >
            Submit
          </Button>
        )}
        {current > 0 && (
          <Button style={{ marginRight: "8px" }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        <Button
          onClick={() =>
            Modal.confirm({
              icon: (
                <li className={`fa fa-exclamation-circle ${styles}`}>
                  <span>Confirm</span>
                </li>
              ),
              content:
                "The form data will not be saved. Are you sure you want to exit?",
              okText: "Exit",
              cancelText: "Stay",
              onOk: () => (window.location = "/"),
            })
          }
        >
          Exit
        </Button>
      </div>
    </div>
  );
};

const styles = css`
  & {
    font-size: 30px !important;
    span {
      margin-left: 10px;
      font-size: 24px !important;
    }
  }
`;

export default LoanFormView;
