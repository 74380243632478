import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";

const PrivateRoutes = ({ component: Component, ...rest }) => {
  const [authenticate, setAuthenticate] = useState({
    fetching: true,
    auth: false,
  });
  const { fetching, auth } = authenticate;
  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_SERVICE_API}/api/admin/user/auth`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((res) => {
        res.status === 200 && setAuthenticate({ fetching: false, auth: true });
      })
      .catch((error) => setAuthenticate({ fetching: false, auth: false }));
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        return fetching ? (
          ""
        ) : !fetching && auth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { prevPath: rest.computedMatch.url },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoutes;
