import React, { useState, useEffect } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import {
  objectEmpty,
  requiredString,
  phone,
  renderStates,
} from "../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../utils/Debugger";
import FormError from "../utils/FormError";
import { sponsorValues } from "../consts/formValues";
import { Message, FormNotification } from "../utils/Message";
import axios from "axios";
import { Button } from "antd";
import { css } from "emotion";


const SponsorApplication = (props) => {
  const [formData, setFormData] = useState(sponsorValues);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [submitted, setSubmitted] = useState({ success: false, error: false });

  useEffect(() => {
    submitted.error && FormNotification(null, "error");
  }, [submitted]);

  const onSubmit = (e) => {
    setSubmitted({ success: false, error: false });
    axios
    .post("/api/sponsor", formData)
    .then((res) => setSubmitted({ success: true, error: false }))
    .catch((error) => setSubmitted({ success: false, error: true }));
  };
  
  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validationSchema = Yup.object({
    firstName: requiredString,
    lastName: requiredString,
    businessName: requiredString,
    address: Yup.string().required("Required"),
    address2: Yup.string(),
    city: requiredString,
    state: requiredString,
    zip: Yup.number()
      .required("Required")
      .typeError("Must not include any special characters"),
    phone: phone,
    email: Yup.string().email().required("Required"),
    grossSalary: Yup.number()
      .required("Required")
      .typeError("Must be a number, e.g 45000"),
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? setBtnDisabled(true) : setBtnDisabled(false)
      );
    }, [errors]);
    return null;
  };

  if (submitted.success) {
    return (
      <Message>
        <h4>Your application has been submitted successfully!</h4>
        <p>
          Thank you for submitting the application. We will contact you very
          soon to complete your sponsorship process and getting you on onboard
          with MFC.
        </p>
      </Message>
    );
  }

  return (
    <>
      <div className={`${styles} container`}>
        <h2>Sponsor Application</h2>
        {/* <Debugger data={loanInfoValues} /> */}
        <Formik initialValues={formData} validationSchema={validationSchema}>
          <Form onChange={onChange}>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="firstName">First Name</label>
                <Field
                  className="form-control"
                  name="firstName"
                  placeholder="First Name"
                />
                <ErrorMessage component={FormError} name="firstName" />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="lastName">Last Name</label>
                <Field
                  className="form-control"
                  name="lastName"
                  placeholder="Last Name"
                />
                <ErrorMessage component={FormError} name="lastName" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="businessName">Business Name</label>
                <Field
                  className="form-control"
                  name="businessName"
                  placeholder="Business Name"
                />
                <ErrorMessage component={FormError} name="businessName" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-lg-6">
                <label htmlFor="address">Business Street Address</label>
                <Field
                  className="form-control"
                  name="address"
                  placeholder="1234 Main St"
                />
                <ErrorMessage component={FormError} name="address" />
              </div>

              <div className="form-group col-lg-4">
                <label htmlFor="address2">Suite, Apartment, Unit, etc.</label>
                <Field
                  className="form-control"
                  name="address2"
                  placeholder="Apartment, studio, or floor"
                />
                <ErrorMessage component={FormError} name="address2" />
              </div>

              <div className="form-group col-lg-4">
                <label htmlFor="city">City</label>
                <Field className="form-control" name="city" />
                <ErrorMessage component={FormError} name="city" />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="state">State</label>
                <Field name="state" className="custom-select" as="select">
                  <option value="">Select...</option>
                  {renderStates()}
                </Field>
                <ErrorMessage component={FormError} name="state" />
              </div>

              <div className="form-group col-lg-2 col-md-4">
                <label htmlFor="zip">Zip</label>
                <Field className="form-control" name="zip" />
                <ErrorMessage component={FormError} name="zip" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="phone">Phone Number:</label>
                <Field type="tel" className="form-control" name="phone" />
                <ErrorMessage component={FormError} name="phone" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="email">Email:</label>
                <Field
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="example@email.com"
                />
                <ErrorMessage component={FormError} name="email" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="grossSalary">Gross annual Income</label>
                <Field name="grossSalary" className="form-control" />
                <ErrorMessage component={FormError} name="grossSalary" />
              </div>
            </div>
            <Button
              type="primary"
              disabled={btnDisabled}
              onClick={() => onSubmit()}
            >
              Submit
            </Button>
            <Context />
          </Form>
        </Formik>
      </div>
    </>
  );
};

const styles = css`
  & {
    padding: 40px 60px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 20px auto;

    h2 {
      padding: 40px 0;
    }

    button {
      margin: 15px 0;
    }

    @media screen and (max-width: 635px) {
      max-width: 95% !important;

      h2 {
        font-size: 1.5em;
      }
      p,
      label {
        font-size: 0.9em;
      }
    }
  }
`;

export default SponsorApplication;
