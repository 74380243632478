import React from "react";
import ApplicationPDFRenderer from "../pdf/ApplicationPDFRenderer";
import asyncComponent from "../../../DynamicImport";
import { Helmet } from "react-helmet";
import FormList from "./FormList";
import Users from "./Users";
import MFCAssistDsiclaimerForm from "../forms/MFCAssistDisclaimerForm";

const Dashboard = asyncComponent(() => import("./Dashboard"));

export const DashboardView = (props) => {
  return <Dashboard content={() => "Future updates coming!"} />;
};

export const FormListView = (props) => {
  return (
    <>
      <Dashboard content={() => <FormList />}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Muslims For Change</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </Dashboard>
    </>
  );
};

export const UserListView = (props) => {
  return (
    <>
      <Dashboard content={() => <Users />}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Muslims For Change</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </Dashboard>
    </>
  );
};

export const MFCAssistDsiclaimerFormView = (props) => {
  return (
    <>
      <Dashboard content={() => <MFCAssistDsiclaimerForm />}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Muslims For Change</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </Dashboard>
    </>
  );
};

export const ApplicationPDFRendererView = ({ match }) => {
  return (
    <>
      <Dashboard
        content={() => <ApplicationPDFRenderer paramsId={match.params.id} />}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Muslims For Change</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
      </Dashboard>
    </>
  );
};
