import "../../../assets/css/singleApplication.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { unNestObject, formatPhone } from "../../utils/helpers";
import {
  Page,
  Text,
  Document,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import FieldGroup from "../../utils/FieldGroup";

const HomeApplicationPDF = ({ initialValues, formId }) => {
  const [dataRequest, setDataRequest] = useState({
    fetching: true,
    error: false,
  });

  const [data, setData] = useState(
    (initialValues && unNestObject(initialValues)) || {}
  );

  const { fetching, error } = dataRequest;

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_SERVICE_API}/api/application/${formId}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        if (!data) {
          setDataRequest({ fetching: false, error: true });
        } else {
          setDataRequest({ fetching: false, error: false });
          setData(data);
        }
      })
      .catch((error) => {
        setDataRequest({ fetching: false, error: true });
      });
  }, []);

  const {
    id,
    exceptions,
    firstName,
    middleName,
    lastName,
    muslimName,
    dob,
    citizen,
    address,
    address2,
    city,
    state,
    zip,
    phone,
    email,
    living,
    filedBancrupcy,
    planForBankrupcy,
    maritalStatus,
    hasCoApplicant,
    coApplicantLivesTogether,
    wifeIsCoApplicant,
    coApplicantFirstName,
    coApplicantMiddleName,
    coApplicantLastName,
    coApplicantMuslimName,
    coApplicantDob,
    coApplicantAddress,
    coApplicantAddress2,
    coApplicantCity,
    coApplicantState,
    coApplicantZip,
    coApplicantLiving,
    coApplicantCitizen,
    coApplicantPhone,
    coApplicantEmail,
    coApplicantFiledBancrupcy,
    coApplicantPlanForBankrupcy,
    currentlyEmployed,
    currentEmployer,
    currentPosition,
    employedSince,
    employerAddress,
    employerAddress2,
    employerCity,
    employerState,
    employerZip,
    employerPhone,
    supervisorName,
    previousEmployer,
    previousPosition,
    grossSalary,
    monthlyIncome,
    coApplicantCurrentlyEmployed,
    coApplicantCurrentEmployer,
    coApplicantCurrentPosition,
    coApplicantEmployedSince,
    coApplicantEmployerAddress,
    coApplicantEmployerAddress2,
    coApplicantEmployerCity,
    coApplicantEmployerState,
    coApplicantEmployerZip,
    coApplicantEmployerPhone,
    coApplicantSupervisorName,
    coApplicantPreviousEmployer,
    coApplicantPreviousPosition,
    coApplicantGrossSalary,
    coApplicantMonthlyIncome,
    loanRequested,
    loanPurpose,
    loanImpact,
    appliedForOtherLoan,
    preferedHomeType,
    stayingInSameState,
    relocateWithinState,
    downpayment,
    mortgageOrRent,
    HOA,
    cableOrInternet,
    homePhone,
    cellPhone,
    homeUtilities,
    groceries,
    outing,
    carLoanOrLease,
    carMaintenance,
    carInsurance,
    homeInsurance,
    healthInsurance,
    pocketMedicalExpenses,
    totalCreditCardDebt,
    creditCardPayment,
    studentLoansPayment,
    tuitionForKids,
    childSupportOrAlimony,
    otherExpenses,
    emergencyName,
    emergencyRelationship,
    emergencyPhone,
    imamName,
    imamPhone,
    masjidName,
    referenceName1,
    referencePhone1,
    referenceName2,
    referencePhone2,
    referenceName3,
    referencePhone3,
    coApplicantMortgageOrRent,
    coApplicantHOA,
    coApplicantCableOrInternet,
    coApplicantHomePhone,
    coApplicantCellPhone,
    coApplicantHomeUtilities,
    coApplicantGroceries,
    coApplicantOuting,
    coApplicantCarLoanOrLease,
    coApplicantCarMaintenance,
    coApplicantCarInsurance,
    coApplicantHomeInsurance,
    coApplicantHealthInsurance,
    coApplicantPocketMedicalExpenses,
    coApplicantTotalCreditCardDebt,
    coApplicantCreditCardPayment,
    coApplicantStudentLoansPayment,
    coApplicantTuitionForKids,
    coApplicantChildSupportOrAlimony,
    coApplicantOtherExpenses,
    signatureName,
    agreeDate1,
    coSignatureName,
  } = data;
  //REMOVE
  if (fetching) {
    return <Spin size="large" className="spinner" />;
  } else if (error) {
    return (
      <div className="fetchError">
        <Text>
          Unable to retrieve application data. Please try again at a later time!
        </Text>
      </div>
    );
  }

  return (
    <div className="application application-container">
      <PDFViewer width="100%" height="700px">
        <Document>
          <Page size="A4" style={styles.view}>
            <Text style={styles.title}>Loan Application</Text>

            <section>
              <Text break style={styles.subtitle}>
                Applicant Information: {id}
              </Text>
              <FieldGroup
                values={[
                  { name: "First Name", value: firstName },
                  { name: "Middle Name", value: `${middleName || ""}` },
                  ,
                  { name: "Last Name", value: lastName },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Date of Birth", value: dob },
                  { name: "US Citizen", value: citizen },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Muslim Name", value: muslimName || "N/A" },
                  { name: "Married", value: maritalStatus },
                  { name: "Currently Employed", value: currentlyEmployed },
                ]}
              />

              {hasCoApplicant === "Yes" && (
                <FieldGroup
                  values={[
                    {
                      name: "Has Co-Applicant",
                      value: hasCoApplicant,
                    },
                    maritalStatus === "Yes" && {
                      name: "Spouse is Co-Applicant",
                      value: wifeIsCoApplicant,
                    },
                  ]}
                />
              )}

              <FieldGroup
                values={[
                  {
                    name: "Live with Co-Applicant",
                    value: coApplicantLivesTogether,
                  },
                  { name: "Living Status", value: living },
                ]}
              />

              <FieldGroup
                values={[{ name: "Street Address", value: address }]}
              />

              <FieldGroup
                values={[
                  { name: "Suite, Apt, Unit.", value: address2 || "" },
                  { name: "City", value: city },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "State", value: state },
                  { name: "Zip", value: zip },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Phone", value: formatPhone(phone) },
                  { name: "Email", value: email },
                ]}
              />

              <FieldGroup
                values={[
                  {
                    name: "Have filed for bankrupcy before",
                    value: filedBancrupcy,
                  },
                  { name: "Will file for bankrupcy", value: planForBankrupcy },
                ]}
              />

              <FieldGroup
                values={[{ name: "Exceptions", value: exceptions || "" }]}
              />
            </section>

            {currentlyEmployed === "Yes" && (
              <section>
                <Text break style={styles.subtitle}>
                  Employment Information
                </Text>

                <FieldGroup
                  values={[
                    { name: "Current Employer", value: currentEmployer },
                  ]}
                />

                <FieldGroup
                  values={[
                    { name: "Position Title", value: currentPosition },
                    { name: "Employed Since", value: employedSince },
                  ]}
                />

                <FieldGroup
                  values={[
                    { name: "Gross Income", value: `$${grossSalary}` },
                    ,
                    { name: "Net Monthly Income", value: `$${monthlyIncome}` },
                    ,
                  ]}
                />

                <FieldGroup
                  values={[
                    { name: "Employer Street Address", value: employerAddress },
                  ]}
                />

                <FieldGroup
                  values={[
                    {
                      name: "Suite, Apt, Unit.",
                      value: employerAddress2 || "N/A",
                    },
                    { name: "City", value: employerCity },
                  ]}
                />

                <FieldGroup
                  values={[
                    { name: "State", value: employerState },
                    { name: "Zip", value: employerZip },
                  ]}
                />

                <FieldGroup
                  values={[
                    {
                      name: "Employer Phone",
                      value: formatPhone(employerPhone),
                    },
                    { name: "Manager's Name", value: supervisorName },
                  ]}
                />

                <FieldGroup
                  values={[
                    { name: "Previous Employer", value: previousEmployer },
                    {
                      name: "Previous Position Title",
                      value: previousPosition,
                    },
                  ]}
                />
              </section>
            )}

            {hasCoApplicant === "Yes" && (
              <>
                <section>
                  <Text break style={styles.subtitle}>
                    Co-Applicant Information
                  </Text>

                  <FieldGroup
                    values={[
                      { name: "First Name", value: coApplicantFirstName },
                      {
                        name: "Middle Name",
                        value: `${coApplicantMiddleName || ""}`,
                      },
                      ,
                      { name: "Last Name", value: coApplicantLastName },
                    ]}
                  />

                  <FieldGroup
                    values={[
                      { name: "Date of Birth", value: coApplicantDob },
                      { name: "US Citizen", value: coApplicantCitizen },
                    ]}
                  />

                  <FieldGroup
                    values={
                      coApplicantLivesTogether === "Yes"
                        ? [
                            {
                              name: "Muslim Name",
                              value: coApplicantMuslimName || "N/A",
                            },
                            {
                              name: "Currently Employed",
                              value: coApplicantCurrentlyEmployed,
                            },
                          ]
                        : [
                            {
                              name: "Muslim Name",
                              value: coApplicantMuslimName || "N/A",
                            },
                            {
                              name: "Currently Employed",
                              value: coApplicantCurrentlyEmployed,
                            },
                            { name: "Living Status", value: coApplicantLiving },
                          ]
                    }
                  />

                  {coApplicantLivesTogether === "No" && (
                    <>
                      <FieldGroup
                        values={[
                          { name: "Street Address", value: coApplicantAddress },
                        ]}
                      />

                      <FieldGroup
                        values={[
                          {
                            name: "Suite, Apt, Unit.",
                            value: coApplicantAddress2 || "",
                          },
                          { name: "City", value: coApplicantCity },
                        ]}
                      />

                      <FieldGroup
                        values={[
                          { name: "State", value: coApplicantState },
                          { name: "Zip", value: coApplicantZip },
                        ]}
                      />
                    </>
                  )}

                  <FieldGroup
                    values={[
                      { name: "Phone", value: formatPhone(coApplicantPhone) },
                      { name: "Email", value: coApplicantEmail },
                    ]}
                  />

                  <FieldGroup
                    values={[
                      {
                        name: "Have filed for bankrupcy before",
                        value: coApplicantFiledBancrupcy,
                      },
                      {
                        name: "Will file for bankrupcy",
                        value: coApplicantPlanForBankrupcy,
                      },
                    ]}
                  />
                </section>

                {coApplicantCurrentlyEmployed === "Yes" && (
                  <section>
                    <Text break style={styles.subtitle}>
                      Co-Applicant Employment Information
                    </Text>

                    <FieldGroup
                      values={[
                        {
                          name: "Current Employer",
                          value: coApplicantCurrentEmployer,
                        },
                      ]}
                    />

                    <FieldGroup
                      values={[
                        {
                          name: "Position Title",
                          value: coApplicantCurrentPosition,
                        },
                        {
                          name: "Employed Since",
                          value: coApplicantEmployedSince,
                        },
                      ]}
                    />

                    <FieldGroup
                      values={[
                        {
                          name: "Gross Income",
                          value: `$${coApplicantGrossSalary}`,
                        },
                        ,
                        {
                          name: "Net Monthly Income",
                          value: `$${coApplicantMonthlyIncome}`,
                        },
                        ,
                      ]}
                    />

                    <FieldGroup
                      values={[
                        {
                          name: "Employer Street Address",
                          value: coApplicantEmployerAddress,
                        },
                      ]}
                    />

                    <FieldGroup
                      values={[
                        {
                          name: "Suite, Apt, Unit.",
                          value: coApplicantEmployerAddress2 || "N/A",
                        },
                        { name: "City", value: coApplicantEmployerCity },
                      ]}
                    />

                    <FieldGroup
                      values={[
                        { name: "State", value: coApplicantEmployerState },
                        { name: "Zip", value: coApplicantEmployerZip },
                      ]}
                    />

                    <FieldGroup
                      values={[
                        {
                          name: "Employer Phone",
                          value: formatPhone(coApplicantEmployerPhone),
                        },
                        {
                          name: "Manager's Name",
                          value: coApplicantSupervisorName,
                        },
                      ]}
                    />

                    <FieldGroup
                      values={[
                        {
                          name: "Previous Employer",
                          value: coApplicantPreviousEmployer,
                        },
                        {
                          name: "Previous Position Title",
                          value: coApplicantPreviousPosition,
                        },
                      ]}
                    />
                  </section>
                )}
              </>
            )}

            <section>
              <Text break style={styles.subtitle}>
                Loan Request Information
              </Text>

              <FieldGroup
                values={[
                  { name: "Loan Amount", value: `$${loanRequested}` },
                  ,
                  {
                    name: "Applied for other loans",
                    value: appliedForOtherLoan,
                  },
                ]}
              />

              <FieldGroup
                values={[
                  {
                    name: "Staying in the same state",
                    value: stayingInSameState,
                  },
                  {
                    name: "Willing to relocate within state",
                    value: relocateWithinState,
                  },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Down Payment", value: downpayment },
                  { name: "Prefered Home Type", value: preferedHomeType },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Loan purpose", value: loanPurpose },
                  { name: "Loan impact", value: loanImpact },
                ]}
              />
            </section>

            <section>
              <Text break style={styles.subtitle} break>
                Monthly Expenses
              </Text>

              <FieldGroup
                values={[
                  { name: "Mortgage/Rent", value: `$${mortgageOrRent}` },
                  { name: "HOA", value: `$${HOA}` },
                ]}
              />
              <FieldGroup
                values={[
                  { name: "Cable/Internet", value: `$${cableOrInternet}` },
                  { name: "Home Phone", value: `$${homePhone}` },
                ]}
              />
              <FieldGroup
                values={[
                  { name: "Cell Phone", value: `$${cellPhone}` },
                  { name: "Electric/Gas/Water", value: `$${homeUtilities}` },
                ]}
              />
              <FieldGroup
                values={[
                  { name: "Groceries", value: `$${groceries}` },
                  { name: "Dinning out/Entertainment", value: `$${outing}` },
                ]}
              />
              <FieldGroup
                values={[
                  { name: "Car Loan/Lease", value: `$${carLoanOrLease}` },
                  { name: "Car Maintenance", value: `$${carMaintenance}` },
                ]}
              />
              <FieldGroup
                values={[
                  { name: "Car Insurance", value: `$${carInsurance}` },
                  { name: "Home Insurance", value: `$${homeInsurance}` },
                ]}
              />
              <FieldGroup
                values={[
                  { name: "Health Insurance", value: `$${healthInsurance}` },
                  {
                    name: "Out of pocket medical expenses",
                    value: `$${pocketMedicalExpenses || "0"}`,
                  },
                ]}
              />
              <FieldGroup
                values={[
                  {
                    name: "Total Credit card debt",
                    value: `$${totalCreditCardDebt || "0"}`,
                  },
                  {
                    name: "Credit card monthly payment",
                    value: `$${creditCardPayment || "0"}`,
                  },
                ]}
              />
              <FieldGroup
                values={[
                  {
                    name: "Student Loans Payment",
                    value: `$${studentLoansPayment || "0"}`,
                  },
                  {
                    name: "Tuition for all children",
                    value: `$${tuitionForKids || "0"}`,
                  },
                ]}
              />
              <FieldGroup
                values={[
                  {
                    name: "Child Support/Alimony",
                    value: `$${childSupportOrAlimony || "0"}`,
                  },
                  {
                    name: "Other expenses, please describe",
                    value: `$${otherExpenses || "0"}`,
                  },
                ]}
              />
            </section>

            {hasCoApplicant === "Yes" && (
              <section>
                <Text break style={styles.subtitle} break>
                  Co Applicant Monthly Expenses
                </Text>

                <FieldGroup
                  values={[
                    {
                      name: "Mortgage/Rent",
                      value: `$${coApplicantMortgageOrRent || "0"}`,
                    },
                    { name: "HOA", value: `$${coApplicantHOA || "0"}` },
                  ]}
                />
                <FieldGroup
                  values={[
                    {
                      name: "Cable/Internet",
                      value: `$${coApplicantCableOrInternet || "0"}`,
                    },
                    {
                      name: "Home Phone",
                      value: `$${coApplicantHomePhone || "0"}`,
                    },
                  ]}
                />
                <FieldGroup
                  values={[
                    {
                      name: "Cell Phone",
                      value: `$${coApplicantCellPhone || "0"}`,
                    },
                    {
                      name: "Electric/Gas/Water",
                      value: `$${coApplicantHomeUtilities || "0"}`,
                    },
                  ]}
                />
                <FieldGroup
                  values={[
                    {
                      name: "Groceries",
                      value: `$${coApplicantGroceries || "0"}`,
                    },
                    {
                      name: "Dinning out/Entertainment",
                      value: `$${coApplicantOuting || "0"}`,
                    },
                  ]}
                />
                <FieldGroup
                  values={[
                    {
                      name: "Car Loan/Lease",
                      value: `$${coApplicantCarLoanOrLease || "0"}`,
                    },
                    {
                      name: "Car Maintenance",
                      value: `$${coApplicantCarMaintenance || "0"}`,
                    },
                  ]}
                />
                <FieldGroup
                  values={[
                    {
                      name: "Car Insurance",
                      value: `$${coApplicantCarInsurance || "0"}`,
                    },
                    {
                      name: "Home Insurance",
                      value: `$${coApplicantHomeInsurance || "0"}`,
                    },
                  ]}
                />
                <FieldGroup
                  values={[
                    {
                      name: "Health Insurance",
                      value: `$${coApplicantHealthInsurance || "0"}`,
                    },
                    {
                      name: "Out of pocket medical expenses",
                      value: `$${coApplicantPocketMedicalExpenses || "0"}`,
                    },
                  ]}
                />
                <FieldGroup
                  values={[
                    {
                      name: "Total Credit card debt",
                      value: `$${coApplicantTotalCreditCardDebt || "0"}`,
                    },
                    {
                      name: "Credit card monthly payment",
                      value: `$${coApplicantCreditCardPayment || "0"}`,
                    },
                  ]}
                />
                <FieldGroup
                  values={[
                    {
                      name: "Student Loans Payment",
                      value: `$${coApplicantStudentLoansPayment || "0"}`,
                    },
                    {
                      name: "Tuition for all children",
                      value: `$${coApplicantTuitionForKids || "0"}`,
                    },
                  ]}
                />
                <FieldGroup
                  values={[
                    {
                      name: "Child Support/Alimony",
                      value: `$${coApplicantChildSupportOrAlimony || "0"}`,
                    },
                    {
                      name: "Other expenses, please describe",
                      value: `$${coApplicantOtherExpenses || "0"}`,
                    },
                  ]}
                />
              </section>
            )}
            <section>
              <Text break style={styles.subtitle}>
                Emergency Contact
              </Text>
              <FieldGroup
                values={[
                  { name: "Name", value: emergencyName },
                  { name: "Phone", value: formatPhone(emergencyPhone) },
                  { name: "Relationship", value: emergencyRelationship },
                ]}
              />
            </section>

            <section>
              <Text break style={styles.subtitle}>
                Imam Reference
              </Text>
              <FieldGroup
                extraSpace={true}
                values={[
                  { name: "Imam Name", value: imamName },
                  { name: "Phone", value: formatPhone(imamPhone) },
                  { name: "Masjid Name", value: masjidName },
                ]}
              />
            </section>

            <section>
              <Text break style={styles.subtitle}>
                Other Reference
              </Text>
              <FieldGroup
                values={[
                  { name: "Reference Name 1", value: referenceName1 },
                  { name: "Phone", value: formatPhone(referencePhone1) },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Reference Name 2", value: referenceName2 } || "",
                  { name: "Phone", value: formatPhone(referencePhone2) || "" },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Reference Name 3", value: referenceName3 || "" },
                  { name: "Phone", value: formatPhone(referencePhone3) || "" },
                ]}
              />
            </section>
          </Page>

          <Page size="A4">
            <View style={styles.agreePage}>
              <View style={styles.agreeTextView}>
                <Text style={styles.agreeSubtitle}>APPLICANT CONSENT</Text>
                <Text style={styles.agreeText}>
                  I/We hereby certify that the information provided is true,
                  correct, and complete. I authorize Muslims For Change, Inc.
                  (MFC) to investigate my credit, credential and/or employment
                  history and authorize MFC to make inquiries and gather
                  information that it feels is necessary concerning statements
                  made on this application. It is further agreed that MFC will
                  be promptly notified of any material changes in this
                  information.
                </Text>

                <Text style={styles.agreeSubtitle}>
                  LIMITATION OF LIABILITY
                </Text>
                <Text style={styles.agreeText}>
                  Borrower(s) understands and agrees that Muslims For Change,
                  Inc. (MFC) provides interest free loans to qualified members
                  of the Muslim community. Borrower further understands and
                  agrees that MFC’s sole mission and purpose is to provide
                  assistance to qualified individuals, and while MFC may offer
                  referrals to third parties service providers, MFC does not
                  engage in or offer any clinical or non-financial counseling
                  services. Borrower agrees that MFC will not under any
                  circumstances be held liable for any damages whatsoever
                  arising out of or relating to its financial services or any
                  referrals it may provide, including, without limitation, any
                  special, indirect, incidental, consequential or exemplary
                  damages. Borrower agrees to indemnify and hold harmless MFC
                  and its officers, directors, employees, agents or volunteers
                  from any and all claims, injuries, damages, losses or suits
                  (including reasonable attorney fees), arising out of or in
                  connection with the performance of its services.
                </Text>
              </View>

              <View style={styles.agreeSignatureView}>
                <Text style={styles.subtitle}>Signatures</Text>
                <FieldGroup
                  values={[
                    { name: "Applicant Full Name:", value: signatureName },
                    { name: "Date", value: agreeDate1 },
                  ]}
                />

                <FieldGroup
                  values={[
                    { name: "Co-Applicant Full Name:", value: coSignatureName },
                    { name: "Date", value: agreeDate1 },
                  ]}
                />
              </View>
            </View>
          </Page>

          <Page size="A4">
            <View style={styles.agreePage}>
              <View style={styles.agreeTextView}>
                <Text style={styles.title}>LOAN AGREEMENT</Text>

                <section style={{ marginTop: "20px" }}>
                  <FieldGroup
                    values={[
                      { name: "Loan Amount:", value: `$${loanRequested}` },
                      { name: "Loan Date:", value: agreeDate1 },
                    ]}
                  />
                </section>
                <View style={styles.agreeView}>
                  <Text style={styles.agreeText}>
                    Muslims For Change, Inc. (MFC) is reviewing a loan
                    application from the Borrower for an interest free loan in
                    the sum of the Loan Amount. If the loan is approved, I agree
                    to fully, absolutely and unconditionally guarantee full
                    payment of the Loan Amount. I also agree that a creditor can
                    collect this debt from me and that I will pay any collection
                    costs if a creditor gets involved, which can increase the
                    initial borrowed amount.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.agreeText}>
                    All persons signing the Loan Agreement for this loan are
                    jointly and severally liable for payment of the Loan Amount.
                    This means that each Applicant/Co-Applicant is liable for
                    the full amount and, as such, could be called upon to repay
                    the total balance due.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.agreeText}>
                    I recognize that this Loan Agreement creates a binding
                    obligation on me until the Loan Amount is fully paid. I
                    understand that typical loan repayment terms for the
                    Borrower are 80 to 120 equal monthly payments depending upon
                    the size of the loan, beginning from one months after the
                    Borrower receives the loan and signs the loan Promissory
                    Note. The actual repayment terms for the Borrower may vary
                    somewhat from these typical terms. I recognize that defaults
                    may be reported to appropriate credit rating and collection
                    agencies.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.agreeText}>
                    Borrower understands and agrees that any and all disputes
                    which occur as a result of this loan and which are unable to
                    be resolved between the parties directly shall be subject to
                    binding arbitration by an MFC appointed Kadi. Should any
                    party fail to abide by the rulings of the Kadi, the other
                    party may pursue enforcement of the ruling to the degree
                    allowed by the Kadi or MFC’s Sharia authorities. All parties
                    to any loan and or agreement agree to be bound by any and
                    all rulings of the appointed Kadi.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.agreeText}>
                    All matters affecting the interpretation of this Agreement
                    and the rights of the parties hereto shall be governed by
                    the laws of the State of Georgia.
                  </Text>
                </View>
              </View>

              <View style={styles.agreeSignatureView}>
                <Text break style={styles.subtitle}>
                  Signatures
                </Text>
                <FieldGroup
                  values={[
                    { name: "Applicant Full Name:", value: signatureName },
                    { name: "Date", value: agreeDate1 },
                  ]}
                />

                <FieldGroup
                  values={[
                    { name: "Co-Applicant Full Name:", value: coSignatureName },
                    { name: "Date", value: agreeDate1 },
                  ]}
                />
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

const styles = StyleSheet.create({
  view: {
    color: "rgb(80, 79, 79)",
    fontFamily: "Times-Roman",
    paddingBottom: "20px",
    paddingTop: "30px",
  },
  title: {
    fontSize: 20,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    margin: "20px 0",
    textAlign: "center",
  },
  agreePage: {
    fontFamily: "Times-Roman",
    color: "rgb(80, 79, 79)",
  },
  agreeView: {
    paddingTop: "20px",
  },
  agreeTextView: {
    paddingTop: "20px",
    margin: "0 70px",
  },
  agreeSubtitle: {
    fontSize: 16,
    margin: "20px 0",
    textAlign: "center",
  },
  agreeText: {
    fontSize: 12,
  },
  agreeSignatureView: { 
    margin: "30px 40px",
  },
});

export default HomeApplicationPDF;
