import React, { useState, useEffect } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import {
  objectEmpty,
  requiredString,
  phone,
  renderStates,
} from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../utils/Debugger";
import FormError from "../../utils/FormError";
import { css } from "emotion";

const LenderApplication = ({ disableBtn, initialValues, setInitialValues }) => {
  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      [e.target.name]: e.target.value,
    });
  };

  const validationSchema = Yup.object({
    firstName: requiredString,
    lastName: requiredString,
    address: Yup.string().required("Required"),
    address2: Yup.string(),
    city: requiredString,
    state: requiredString,
    zip: Yup.number()
      .required("Required")
      .typeError("Must not include any special characters"),
    phone: phone,
    email: Yup.string().email().required("Required"),
    lendingAmount: Yup.number()
      .required("Required")
      .typeError("Must be a number, e.g 2000"),
    willingToWait: requiredString,
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      <div className={`${styles} container`}>
        {/* <Debugger data={initialValues} /> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form onChange={onChange}>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="firstName">First Name</label>
                <Field
                  className="form-control"
                  name="firstName"
                  placeholder="First Name"
                />
                <ErrorMessage component={FormError} name="firstName" />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="lastName">Last Name</label>
                <Field
                  className="form-control"
                  name="lastName"
                  placeholder="Last Name"
                />
                <ErrorMessage component={FormError} name="lastName" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-lg-6">
                <label htmlFor="address">Street Address</label>
                <Field
                  className="form-control"
                  name="address"
                  placeholder="1234 Main St"
                />
                <ErrorMessage component={FormError} name="address" />
              </div>

              <div className="form-group col-lg-4">
                <label htmlFor="address2">Suite, Apartment, Unit, etc.</label>
                <Field
                  className="form-control"
                  name="address2"
                  placeholder="Apartment, studio, or floor"
                />
                <ErrorMessage component={FormError} name="address2" />
              </div>

              <div className="form-group col-lg-4">
                <label htmlFor="city">City</label>
                <Field className="form-control" name="city" />
                <ErrorMessage component={FormError} name="city" />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="state">State</label>
                <Field name="state" className="custom-select" as="select">
                  <option value="">Select...</option>
                  {renderStates()}
                </Field>
                <ErrorMessage component={FormError} name="state" />
              </div>

              <div className="form-group col-lg-2 col-md-4">
                <label htmlFor="zip">Zip</label>
                <Field className="form-control" name="zip" />
                <ErrorMessage component={FormError} name="zip" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="phone">Phone Number:</label>
                <Field type="tel" className="form-control" name="phone" />
                <ErrorMessage component={FormError} name="phone" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="email">Email:</label>
                <Field
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="example@email.com"
                />
                <ErrorMessage component={FormError} name="email" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="lendingAmount">Amount Lending</label>
                <Field name="lendingAmount" className="form-control" />
                <ErrorMessage component={FormError} name="lendingAmount" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4 col-lg-4">
                <label className="col-form-label" htmlFor="willingToWait">
                  Are you willing to wait at least 6 years to recoup your loan?
                </label>
                <Field
                  className="custom-select"
                  name="willingToWait"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage component={FormError} name="willingToWait" />
              </div>
            </div>
            <Context />
          </Form>
        </Formik>
      </div>
    </>
  );
};

const styles = css`
  & {
    button {
      margin: 15px 0;
    }

    @media screen and (max-width: 635px) {
      max-width: 95% !important;

      p,
      label {
        font-size: 0.9em;
      }
    }
  }
`;

export default LenderApplication;
