import "../../assets/css/incentive.css";
import React from "react";
import transparency from "../../assets/images/transparent.svg";
import dollar from "../../assets/images/dollar.svg";
import partner from "../../assets/images/partner.svg";
import plant from "../../assets/images/plant.svg";
import SmallBanner from "../utils/SmallBanner";
import BigBanner from "../utils/BigBanner";
import bannerImg from "../../assets/images/helpingHand.webp";
import Intro from "../utils/Intro";
import { css } from "emotion";


const Volunteer = () => {
  return (
    <div className={styles}>
      <BigBanner
        img={bannerImg}
        btnText="Volunteer"
        path="/forms/volunteer-application"
      >
        <h1>Volunteer</h1>
        <p>
          A small effort can leave a great impact. Join us to make this dream a
          reality together for a well rounded Muslim community.
        </p>
      </BigBanner>

      <div className="incentive-section">
        <Intro
          header="We Value Our Volunteers"
          text="Thank you for considering to become a part of MFC and your willingness 
          to help bring change to the Muslim community living in the United States
          by volunteering your time, knowledge, skills, and much more. May Allah reward 
          you for your intentions and make you of those who uplift their community!"
        />

        <section>
          <div className="why-mfc">
            <h1 className="intro-header">
              Why <span>MFC</span>
            </h1>

            <div className="volunteer-features">
              <div className="item">
                <img src={plant} alt="Plant" />
                <div className="content">
                  <span className="title">
                    <strong>Empowering</strong>
                  </span>
                  <p className="text">
                    Using MFC's vision, we strive to make a difference in the
                    lives of every Muslim, both as individuals and a collective.
                  </p>
                </div>
              </div>

              <div className="item">
                <img src={transparency} alt="Magnifying Glass" />
                <div className="content">
                  <span className="title">
                    <strong>Transparent</strong>
                  </span>
                  <p className="text">
                    We believe that transparency builds trust between MFC and
                    the community and allows everyone to see the progress we
                    make everyday.
                  </p>
                </div>
              </div>

              <div className="item">
                <img src={dollar} alt="Dollar Sign" />
                <div className="content">
                  <span className="title">
                    <strong>Independent</strong>
                  </span>
                  <p className="text">
                    We are funded through community donations which continually
                    recycle to improve Muslims, allowing us to generate many
                    times the original value to expand the benefits for our
                    community.
                  </p>
                </div>
              </div>

              <div className="item">
                <img src={partner} alt="Handshake" />
                <div className="content">
                  <span className="title">
                    <strong>Make a difference</strong>
                  </span>
                  <p className="text">
                    We hope to be a positive impact in the lives of every Muslim
                    and to spread our influence to change the world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {
        <SmallBanner
          text="Just as much as we are in need of funds, we are in need of human capital. 
          We can make this dream a reality together for a well rounded Muslim community."
          btnText="Volunteer"
          path="/forms/volunteer-application"
        />
      }
    </div>
  );
};

const styles = css`
  & {
    .intro-header {
      text-align: center;
      font-size: 40px;
      font-weight: bold !important;
      margin-bottom: 40px;
    }
    .why-mfc {
      margin-bottom: 50px;
    }
    .why-mfc .intro-header span {
      color: #1c8e12;
    }
    .why-mfc .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 30px 20px;
      background: #ffffff;
      margin: 30px 0;
      border-radius: 20px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
    }
    .why-mfc .item:hover {
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
    .volunteer-features .item img {
      flex-direction: row;
      max-width: 120px;
      max-height: 120px;
      flex-basis: 20%;
      margin: 0 auto;
    }
    .volunteer-features .item p {
      max-width: 800px;
    }
    .volunteer-features .item .content {
      flex-basis: 75%;
      margin-top: 10px;
    }

    @media screen and (max-width: 954px) {
      .why-mfc .item {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
      .volunteer-features .item img {
        flex-direction: row;
        max-width: 80px;
        max-height: 80px;
      }
    }

    @media screen and (max-width: 927px) {
      .volunteer-features p {
        font-size: 0.9em;
      }
    }
  }
`;

export default Volunteer;
