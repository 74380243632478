import React, { useState } from "react";
import axios from "axios";
import { css } from "emotion";
import logo from "../../assets/images/logo.png";
import { Button } from "antd";

const Login = ({ location, ...rest }) => {
  const [state, setState] = useState();
  const [auth, setAuth] = useState({
    fetching: false,
    error: false,
  });

  const onSubmit = (e) => {
    setAuth({ fetching: true, error: false });
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_SERVICE_API}/api/admin/login`, state)
      .then((res) => {
        if (res.status === 200) {
          setAuth({ fetching: false, error: false });
          localStorage.setItem("token", res.data.token);
          window.location = "/";
        }
      })
      .catch((error) => {
        setAuth({ fetching: false, error: true });
      });
  };

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  return (
    <div className={styles}>
      <img src={logo} alt="Logo" />
      <h1>Login</h1>
      {auth.error && (
        <p className="error-message">
          Username or password is wrong. Try again!
        </p>
      )}
      <form className="login-form">
        <div>
          <input
            type="text"
            name="username"
            placeholder="Username"
            onChange={onChange}
          />
        </div>
        <div>
          <input
            type="password"
            name="password"
            placeholder="Password"
            onChange={onChange}
          />
        </div>

        <Button onClick={(e) => onSubmit(e)}>
          Login
        </Button>
      </form>
    </div>
  );
};

const styles = css`
  & {
    text-align: center;
    padding: 40px 0;
    position: relative;
    top: 25vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin: 0 auto;

    img {
      width: 70px;
    }
    h1 {
      font-size: 25px;
      font-weight: 600;
      color: #146334;
    }
    input {
      margin: 10px 0;
      border: 1px black solid;
      width: 220px;
    }
    .ant-btn {
      margin: 10px 0;
      border: none;
      color: white;
      font-size: 14px;
      background-color: #146334;
    }
    .ant-btn:hover,
    .ant-btn:focus {
      opacity: 0.9;
      color: white;
      background-color: #146334;
    }

    .error-message {
      margin-bottom: 0;
      font-size: 13px;
      color: red;
    }
  }
`;
export default Login;
