import React from "react";
import HomeApplicationPDF from "./HomeApplicationPDF";
import LenderApplicationPDF from "./LenderApplicationPDF";
import SponsorApplicationPDF from "./SponsorApplicationPDF";
import VolunteerApplicationPDF from "./VolunteerApplicationPDF";
import BeKnownSurveyPDF from "./BeKnownSurveyPDF";
import MFCAssistDisclaimerPDF from "./MFCAssistDisclaimerPDF";

const ApplicationPDFRenderer = ({ paramsId, ...rest }) => {
  const formId = paramsId.match(/\d/g).join("");
  const renderPDF = () => {
    switch (paramsId) {
      case `MFC-A-${formId}`:
        return <HomeApplicationPDF formId={formId} {...rest} />;
      case `MFC-L-${formId}`:
        return <LenderApplicationPDF formId={formId} {...rest} />;
      case `MFC-S-${formId}`:
        return <SponsorApplicationPDF formId={formId} {...rest} />;
      case `MFC-V-${formId}`:
        return <VolunteerApplicationPDF formId={formId} {...rest} />;
      case `MFC-BKS-${formId}`:
        return <BeKnownSurveyPDF formId={formId} {...rest} />;
      case `MFC-AD-${formId}`:
        return <MFCAssistDisclaimerPDF formId={formId} {...rest} />;
      default:
        break;
    }
  };
  return renderPDF();
};

export default ApplicationPDFRenderer;
