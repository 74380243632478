import React, { useEffect, useState } from "react";
import { Form, Field, Formik, ErrorMessage, useFormikContext } from "formik";
import {
  objectEmpty,
  requiredString,
  phone,
  renderStates,
  populateHousehold
} from "../../utils/helpers";
import * as Yup from "yup";
// import Debugger from "../../utils/Debugger";
import FormError from "../../utils/FormError";
import { css } from "emotion";

const BeKnownSurvey = ({ disableBtn, initialValues, setInitialValues }) => {
  const [householdFields, setHouseholdFields] = useState(false);

  const { householdNumber, fillingOutForSomeoneElse } = initialValues;

  useEffect(() => {
    populateHousehold(parseInt(householdNumber), setHouseholdFields);
  }, [householdNumber]);
  
  const onChange = (e) => {
    if (
      e.target.name.includes("householdAge") ||
      e.target.name.includes("householdGender")
    ) {
      setInitialValues((initialValues) => {
        return {
          ...initialValues,
          householdMemberInfo: {
            ...initialValues.householdMemberInfo,
            [e.target.name]: e.target.value,
          },
        };
      });
    } else {
      setInitialValues({
        ...initialValues,
        [e.target.name]: e.target.value,
      });
    }
  };
  const HousholdMemeberValidation = (num) => {
    let validationObj = {};
    for (let i = 1; i <= num && i <= 10; i++) {
      validationObj = {
        ...validationObj,
        [`householdAge${i}`]: Yup.number()
          .required("Required")
          .typeError("Must be a number, e.g 17"),
        [`householdGender${i}`]: requiredString,
      };
    }

    return validationObj;
  };

  const validationSchema = Yup.object({
    fillingOutForSomeoneElse: requiredString,
    whyFillingOutForSomeoneElse:
      fillingOutForSomeoneElse === "Yes" && Yup.string().required("Required"),
    firstName: requiredString,
    lastName: requiredString,
    age: Yup.number()
      .required("Required")
      .typeError("Must be a number, e.g 25"),
    address: Yup.string().required("Required"),
    address2: Yup.string(),
    city: requiredString,
    state: requiredString,
    zip: Yup.number()
      .required("Required")
      .typeError("Must not include any special characters"),
    phone: phone,
    email: Yup.string().email().required("Required"),
    living: requiredString,
    maritalStatus: requiredString,
    householdNumber: Yup.number()
      .required("Required")
      .typeError("Must be a number, e.g 5"),

    ...(householdNumber && HousholdMemeberValidation(householdNumber)),
    employed: requiredString,
    businessOwner: requiredString,
    grossSalary: Yup.number()
      .required("Required")
      .typeError("Must be a number, e.g 1500"),
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !objectEmpty(errors) ? disableBtn(true) : disableBtn(false)
      );
    }, [errors]);
    return null;
  };

  return (
    <>
      <div className={`${styles} container`}>
        <p>
          Thank you for considering to volunteer to help the Muslim community
          become independent
        </p>
        {/* <Debugger data={initialValues} /> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          <Form onChange={onChange}>
            <div>
              <h4>Attention</h4>
              <p>
                If you are filling out this survey for someone else it is
                crusial that you select yes in the field below. As this helps us
                understand our community's needs better and gives us insight on
                where to allocate funds.
              </p>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="fillingOutForSomeoneElse">
                  Are you filling out this survey for someone else?
                </label>
                <Field
                  className="custom-select"
                  name="fillingOutForSomeoneElse"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage
                  component={FormError}
                  name="fillingOutForSomeoneElse"
                />
              </div>
            </div>

            {fillingOutForSomeoneElse === "Yes" && (
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="whyFillingOutForSomeoneElse">
                    If so, please state the reason:
                  </label>
                  <Field
                    className="form-control"
                    name="whyFillingOutForSomeoneElse"
                    as="textarea"
                  />
                  <ErrorMessage
                    component={FormError}
                    name="whyFillingOutForSomeoneElse"
                  />
                </div>
              </div>
            )}

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="firstName">First Name</label>
                <Field
                  className="form-control"
                  name="firstName"
                  placeholder="First Name"
                />
                <ErrorMessage component={FormError} name="firstName" />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="lastName">Last Name</label>
                <Field
                  className="form-control"
                  name="lastName"
                  placeholder="Last Name"
                />
                <ErrorMessage component={FormError} name="lastName" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="Age">Age</label>
                <Field className="form-control" name="age" placeholder="Age" />
                <ErrorMessage component={FormError} name="age" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-lg-6">
                <label htmlFor="address">Street Address</label>
                <Field
                  className="form-control"
                  name="address"
                  placeholder="1234 Main St"
                />
                <ErrorMessage component={FormError} name="address" />
              </div>

              <div className="form-group col-lg-4">
                <label htmlFor="address2">Suite, Apartment, Unit, etc.</label>
                <Field
                  className="form-control"
                  name="address2"
                  placeholder="Apartment, studio, or floor"
                />
                <ErrorMessage component={FormError} name="address2" />
              </div>

              <div className="form-group col-lg-4">
                <label htmlFor="city">City</label>
                <Field className="form-control" name="city" />
                <ErrorMessage component={FormError} name="city" />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="state">State</label>
                <Field name="state" className="custom-select" as="select">
                  <option value="">Select...</option>
                  {renderStates()}
                </Field>
                <ErrorMessage component={FormError} name="state" />
              </div>

              <div className="form-group col-lg-2 col-md-4">
                <label htmlFor="zip">Zip</label>
                <Field className="form-control" name="zip" />
                <ErrorMessage component={FormError} name="zip" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="phone">Phone Number:</label>
                <Field type="tel" className="form-control" name="phone" />
                <ErrorMessage component={FormError} name="phone" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="email">Email:</label>
                <Field
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="example@email.com"
                />
                <ErrorMessage component={FormError} name="email" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4">
                <label htmlFor="living">Current living status</label>
                <Field className="custom-select" name="living" as="select">
                  <option value="">Select...</option>
                  <option value="Renting">Renting</option>
                  <option value="Homeowner">Outright homeowner</option>
                  <option value="On a mortgage">On a mortgage</option>
                  <option value="Other">other</option>
                </Field>
                <ErrorMessage component={FormError} name="living" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4 col-lg-4">
                <label className="col-form-label" htmlFor="maritalStatus">
                  Are you married?
                </label>
                <Field
                  className="custom-select"
                  name="maritalStatus"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage component={FormError} name="maritalStatus" />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="householdNumber">
                  Number of household excluding you?
                </label>
                <Field className="form-control" name="householdNumber" />
                <ErrorMessage component={FormError} name="householdNumber" />
              </div>
            </div>

            {householdFields &&
              householdFields.map((field) => {
                return field;
              })}
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4 col-lg-4">
                <label className="col-form-label" htmlFor="employed">
                  Are you currently employed
                </label>
                <Field className="custom-select" name="employed" as="select">
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage component={FormError} name="employed" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 col-lg-4 col-lg-4">
                <label className="col-form-label" htmlFor="businessOwner">
                  Are you a business owner?
                </label>
                <Field
                  className="custom-select"
                  name="businessOwner"
                  as="select"
                >
                  <option value="">Select...</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Field>
                <ErrorMessage component={FormError} name="businessOwner" />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="grossSalary">Gross annual Income</label>
                <Field name="grossSalary" className="form-control" />
                <ErrorMessage component={FormError} name="grossSalary" />
              </div>
            </div>
            <Context />
          </Form>
        </Formik>
      </div>
    </>
  );
};

const styles = css`
  & {
    button {
      margin: 15px 0;
    }

    @media screen and (max-width: 635px) {
      max-width: 95% !important;
    }
    h2 {
      font-size: 1.5em;
    }

    p,
    label {
      font-size: 0.9em;
    }
  }
`;

export default BeKnownSurvey;
