import "../../assets/css/incentive.css";
import React from "react";
import BigBanner from "../utils/BigBanner";
import SmallBanner from "../utils/SmallBanner";
import bannerImg from "../../assets/images/sponsor.webp";
import Intro from "../utils/Intro";
import { css } from "emotion";


const Sponsor = () => {
  return (
    <div className={`${styles} sponsor-section`}>
      <BigBanner
        img={bannerImg}
        btnText="Sponsor"
        path="/forms/sponsor-application"
      >
        <h1>Sponsor</h1>
        <p>
          We believe in providing individuals and businesses with an opportunity
          to give back to their community. Join us and be a part of the change.
        </p>
      </BigBanner>

      <div className="incentive-section">
        <Intro
          header="We Value Our Sponsors"
          text="Thank you for considering to become part of MFC and your willingness to help
      bring change to the Muslim community living in the United States. Working with MFC is 
      not only good for our beneficiaries, it’s good for your business. You can transform your 
      business while having a positive impact."
        />

        <section className="ways-we-help-container">
          <div className="ways-we-help">
            <h2>Ways We Can Help</h2>
            <div className="sponsor-feature-container">
              <div className="sponsor-item">
                <span>Reach Audiences</span>
                <p>
                  MFC's position allows it to give you exposure within the
                  Muslim community through the work we do to push your brand
                  forward.
                </p>
              </div>
              <div className="sponsor-item">
                <span>Online Exposure</span>
                <p>
                  We are working to bring a platform which gives Muslim
                  businesses the online exposure to grow their business.
                </p>
              </div>
              <div className="sponsor-item">
                <span>Recognition</span>
                <p>
                  No matter how small or big your company is MFC's goal is to
                  make your business visible to the Muslim community.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <SmallBanner
        text="Our desire to change must be greater than our desire to stay the same."
        btnText="Sponsor"
        path="/forms/sponsor-application"
      />
    </div>
  );
};

const styles = css`
  & {
    .incentive-section section {
      max-width: 1100px;
    }
    .ways-we-help-container {
      margin-bottom: 40px;
      color: #6d6d6d;
    }
    .ways-we-help h2,
    .ways-we-help p,
    .sponsor-item span {
      color: #6d6d6d;
    }
    .ways-we-help h2,
    .sponsor-item span {
      font-weight: bold;
      text-align: center;
    }
    .ways-we-help h2 {
      margin-bottom: 35px;
    }
    .ways-we-help .sponsor-item p {
      margin-top: 15px;
    }
    .ways-we-help .sponsor-feature-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    .ways-we-help .sponsor-item {
      margin: 30px 0;
      flex-basis: 30%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      padding: 20px;
      margin: 0 10px;
      text-align: center;
      margin-bottom: 35px;
    }

    @media screen and (max-width: 927px) {
      .ways-we-help h2 {
        font-size: 1.7em;
      }
      .ways-we-help p {
        font-size: 0.9em;
      }
    }

    @media screen and (max-width: 1059px) {
      .ways-we-help .sponsor-item {
        flex-basis: 40%;
        margin: 0 15px 20px 15px;
      }
    }

    @media screen and (max-width: 728px) {
      .incentive-section section {
        max-width: 90%;
      }
      .ways-we-help .sponsor-feature-container {
        flex-direction: column;
      }
      .ways-we-help .sponsor-item {
        max-width: 100%;
        margin: 15px auto;
      }
    }
  }
`;

export default Sponsor;
