import "../../assets/css/incentive.css";
import React from "react";
import BigBanner from "../utils/BigBanner";
import SmallBanner from "../utils/SmallBanner";
import bannerImg from "../../assets/images/lenderGrowPlant.webp";
import moneyToHome from "../../assets/images/money-to-home.webp";
import create from "../../assets/images/create.svg";
import connect from "../../assets/images/connect.svg";
import build from "../../assets/images/build.svg";
import Intro from "../utils/Intro";
import { css } from "emotion";


const Lender = () => {
  return (
    <div className="lender-qard">
      <BigBanner
        img={bannerImg}
        btnText="Lend"
        path="/forms/lender-application"
      >
        <h1>Become a Lender</h1>
        <p>
          Empower Muslims to be independent in every aspect of their lives. You
          will be able to see the fruits of the seed you grow for the sake of
          Allah.
        </p>
      </BigBanner>

      <div className="incentive-section">
        <Intro
          header="We Value Our Lenders"
          text="Thank you for considering to become part of MFC and your willingness to help expedite 
          the transformation process to bring change to the Muslim community living in the United States. 
          The more united and committed we are, the faster and more we can accomplish!"
        />

        <div className={styles}>
          <div className="lender-qard">
            <div className="qard">
              <h2>Give a Qard Hasan</h2>
              <p>
                Qard hassan is a loan extended mainly for welfare purposes. The
                borrower needs to only pay back the amount borrowed, with no
                interest. Islam does not allow interest. Therefore, a qard hasan
                can be seen as something between giving charity and giving a
                loan.
              </p>

              <a
                href="/forms/lender-application"
                className="btn lend-btn gradient-bg mr-2"
              >
                Lend
              </a>
            </div>

            <img src={moneyToHome} alt="Homeownership leads to financial independence" />
          </div>

          <div className="lender-features-container">
            <h1>What We Do With Your Loan</h1>
            <div className="lender-feautres">
              <div className="item">
                <img src={create} alt="Illustration representing creativity" />
                <div>
                  <span>Create</span>
                  <p>
                    Create a strong independent community, that does not
                    compromise religious priciples and beliefs.
                  </p>
                </div>
              </div>
              <div className="item">
                <img src={connect} alt="Illustration representing socail network" />
                <div>
                  <span>Connect</span>
                  <p>
                    Strive to connect Muslim consumers to Muslim businesses and
                    services to work together and support one another.
                  </p>
                </div>
              </div>
              <div className="item">
                <img src={build} alt="Illustration representing teamwork" />
                <div>
                  <span>Build</span>
                  <p>
                    Build a self sustaining community that meets the demands of
                    the people and ensures their prosperity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SmallBanner
        text="“Who is it that would loan Allah a goodly loan so He will multiply it for him and he will have a noble reward?” (Qur’an 57:11)"
        btnText="Volunteer"
        path="/forms/lender-application"
      />
    </div>
  );
};

const styles = css`
  & {
    .qard h2 {
      font-size: 2.5em;
      margin-bottom: 20px;
    }
    .qard h2,
    .qard p {
      color: #c1c1c1;
    }
    .lender-qard,
    .lender-features-container {
      padding: 96px 0;
      justify-content: center;
    }
    .lender-qard,
    .lender-feautres {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
      background-color: #262626;
      margin: 50px 0 0 0;
    }
    .lender-qard .lend-btn {
      margin-top: 10px;
    }
    .lender-qard .qard {
      max-width: 550px;
    }
    .lender-qard img {
      width: 40%;
      max-width: 550px;
      flex: auto 1;
      padding: 0 15px;
    }
    .lender-qard div {
      flex-basis: 40%;
      padding: 0 15px;
    }
    .lender-features-container {
      text-align: center;
      color: #6d6d6d;
      font-weight: 500;
    }
    .lender-features-container h1 {
      color: #6d6d6d;
      font-weight: bold;
      margin-bottom: 90px;
    }
    .lender-feautres {
      max-width: 980px;
      margin: 0 auto;
      justify-content: space-between;
      background-color: transparent;
    }
    .lender-feautres .item {
      flex-basis: 30%;
    }
    .lender-feautres .item span {
      font-size: 1.4em;
      margin-bottom: 20px;
    }
    .lender-feautres .item p {
      margin-top: 10px;
    }
    .lender-feautres .item img {
      max-width: 30%;
    }

    @media screen and (max-width: 1095px) {
      .lender-qard {
        flex-direction: column-reverse;
      }
      .lender-qard .qard {
        margin-top: 40px;
        text-align: center;
      }
      .lender-qard img {
        width: 100%;
        max-width: 550px;
        padding: 0 15px;
        margin-top: 20px;
      }
      .lender-feautres {
        flex-direction: column;
      }
      .lender-feautres .item {
        max-width: 500px;
        margin: 30px 20px 0 20px;
      }
      .lender-feautres .item img {
        max-width: 100px;
      }
      .lender-features-container h1 {
        font-size: 1.7em;
        margin-bottom: 40px;
      }
      .lender-qard .qard h2 {
        font-size: 1.7em;
      }
      .lender-qard .qard p {
        font-size: 0.9em;
      }
    }
  }
`;

export default Lender;
