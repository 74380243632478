import React from "react";
import { css } from "emotion";
import clouds from "../../assets/images/sunsetSky.webp";

const SmallBanner = ({ text, btnText, path, img }) => {
  const styles = css`
    & {
      .banner-container {
        position: relative;
        z-index: 99;
        padding: 60px 0;
        background-size: cover !important;
        background: url(${img}) no-repeat center;
      }

      .banner-container::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
      }

      .banner-header {
        max-width: 70%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
      .banner-text {
        flex-basis: 72%;
        max-width: 650px;
        position: relative;
        font-weight: 500;
        color: #fff;
      }

      .text p {
        font-size: 1.2em;
        margin: 0;
      }
      .banner-btn {
        flex-basis: 20%;
      }
      a {
        margin: 0 30px;
      }

      @media screen and (max-width: 927px) {
        .banner-container {
          text-align: center;
        }
        .text p {
          font-size: 1em;
        }
        .banner-header {
          max-width: 90%;
        }
        .banner-text {
          flex-basis: 100%;
          font-size: 0.9em;
        }
        .banner-btn {
          margin-top: 10px;
          flex-basis: 30%;
        }
      }
    }
  `;

  return (
    <div className={styles}>
      <div className="banner-container">
        <div className="banner-header">
          <div className="banner-text">
            <p style={{ marginBottom: 0 }}>{text}</p>
          </div>
          <div className="banner-btn">
            {path === "/contribute" ? (
              <a href={path} target="_blank" className="btn gradient-bg">
                {btnText}
              </a>
            ) : (
              <a href={path} className="btn gradient-bg">
                {btnText}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallBanner;

SmallBanner.defaultProps = {
  img: clouds,
};
