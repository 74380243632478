import React from 'react';
import ReactDOM from 'react-dom';             
import App from './App';
import AdminApp from './components/admin/AdminApp';
import 'antd/dist/antd.css';
//assets
import "bootstrap/dist/css/bootstrap.min.css" 
import "./assets/css/font-awesome.min.css"
import "./assets/css/style.css"

const Render = process.env.REACT_APP_MODE === 'admin' ? AdminApp : App

ReactDOM.render(
    <Render />,  
  document.getElementById('root')  
);
