import React, { useEffect, useState } from "react";
import { Form, Field, Formik, useFormikContext, ErrorMessage } from "formik";
// import Debugger from "../../utils/Debugger";
import {
  objectEmpty,
  requiredString,
  renderStates,
  selectRequired,
  dobLimit,
} from "../../utils/helpers";
import * as Yup from "yup";
import FormError from "../../utils/FormError";
import { css } from "emotion";
import { Button } from "antd";
import axios from "axios";
import { Message, FormNotification } from "../../utils/Message";
import jwt from "jsonwebtoken";

const MFCAssistDsiclaimerForm = () => {
  const [submitted, setSubmitted] = useState({ success: false, error: false });
  const { user } = jwt.decode(localStorage.getItem("token"));

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    assistedBy: user,
    assistanceType: "",
    otherAssistance: "",
    DLNumber: "",
    DLState: "",
    paid: "",
    noPaidReason: "",
    signatureName: "",
    agreeDate: "",
    initialAgreement: false,
  });

  useEffect(() => {
    if (values.assistanceType === "Other")
      setValues({
        ...values,
        otherAssistance: "",
      });
  }, [values.assistanceType]);

  const [disableBtn, setDisableBtn] = useState(false);
  const onChange = (e) => {
    let data;

    if (e.target.name === "initialAgreement") {
      data = {
        [e.target.name]: e.target.checked,
      };
    } else {
      data = {
        [e.target.name]: e.target.value,
      };
    }

    setValues({
      ...values,
      ...data,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    let data = values;
    delete data.initialAgreement;

    axios
      .post(
        `${process.env.REACT_APP_SERVICE_API}/api/administrative/assist-disclaimer`,
        data,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then(() => setSubmitted({ success: true, error: false }))
      .catch(() => {
        setSubmitted({ success: false, error: true });
        FormNotification(
          "There was an error while submitting. Try again later!",
          "error"
        );
      });
  };

  const validationSchema = Yup.object({
    firstName: requiredString,
    lastName: requiredString,
    phone: Yup.number()
      .required("Required")
      .test(
        "len",
        "Must be exactly 10 digits",
        (val) => val && val.toString().length === 10
      )
      .typeError("Must not include any special characters"),
    DLNumber: Yup.number()
      .required("Required")
      .typeError("Must not include any special characters"),
    DLState: requiredString,
    dob: Yup.date().required("Required"),
    paid: requiredString,
    assistanceType: requiredString,
    otherAssistance: values.assistanceType === "Other" ? selectRequired : "",
    signatureName: requiredString,
    agreeDate: Yup.date().required("Required"),
  });

  const Context = () => {
    // Grab values and submitForm from context
    const { errors, validateForm } = useFormikContext();
    useEffect(() => {
      validateForm().then((errors) =>
        !values.initialAgreement || !objectEmpty(errors)
          ? setDisableBtn(true)
          : setDisableBtn(false)
      );
    }, [errors]);
    return null;
  };

  if (submitted.success) {
    return (
      <Message>
        <h4>Your disclaimer consent form has been submitted successfully!</h4>
        <p>There is nothing else you need to do. Thank you.</p>
      </Message>
    );
  }

  return (
    <>
      {/* <Debugger data={values} /> */}
      <Formik initialValues={values} validationSchema={validationSchema}>
        <Form onChange={onChange}>
          <div className={`container ${styles}`}>
            <div className="discalimer">
              <h3>Disclaimer</h3>
              <div>
                <p>
                  MUSLIMS FOR CHANGE and any of it's employees or volunteers are
                  not responsible for any false information provided by the
                  individual signing this document. All MUSLIMS FOR CHANGE and
                  all of it's employees and volunteers are only tasked with
                  assisting the individual signing this document and are
                  providing the information provided by the individual signing
                  this document as instructed to do so verbally or through a
                  physical document obtained from the individual signing this
                  document.
                </p>

                <h3>Agreement</h3>
                <p>
                  I hereby certify that all information provided to MUSLIMS FOR
                  CHANGE and any of it's employees or volunteers is true to the
                  best of my knowledge. I also agree that MUSLIMS FOR CHANGE and
                  any of it's employees or volunteers are to not be held
                  accountable for any fraud or fradulant activity that results
                  from the information provided by me. I also agree that the
                  amount paid to MUSLIMS FOR CHANGE in exhange for their
                  assistance services is non-refundable.
                </p>
              </div>
            </div>

            <div className="form-container">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="assistedBy">Assited By</label>
                  <Field
                    className="form-control"
                    name="assistedBy"
                    value={user}
                    disabled
                  />
                  <ErrorMessage component={FormError} name="assistedBy" />
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="assistanceType">Type of Assistance</label>
                  <Field
                    name="assistanceType"
                    className="custom-select"
                    as="select"
                  >
                    <option value="">Select...</option>
                    <option value="School">School</option>
                    <option value="College">College</option>
                    <option value="Financial Aid">Financial Aid</option>
                    <option value="Immigration">Immigration</option>
                    <option value="Unemployment">Unemployment</option>
                    <option value="Business License">Business License</option>
                    <option value="Other">Other</option>
                  </Field>
                  <ErrorMessage component={FormError} name="assistanceType" />
                </div>
              </div>

              {values.assistanceType === "Other" && (
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="otherAssistance">Please explain</label>
                    <Field
                      as="textarea"
                      value={values.otherAssistance}
                      className="form-control"
                      name="otherAssistance"
                    />
                    <ErrorMessage
                      component={FormError}
                      name="otherAssistance"
                    />
                  </div>
                </div>
              )}

              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="firstName">First Name</label>
                  <Field
                    className="form-control"
                    name="firstName"
                    placeholder="First Name"
                  />
                  <ErrorMessage component={FormError} name="firstName" />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="lastName">Last Name</label>
                  <Field
                    className="form-control"
                    name="lastName"
                    placeholder="Last Name"
                  />
                  <ErrorMessage component={FormError} name="lastName" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6 col-lg-4">
                  <label htmlFor="phone">Phone Number:</label>
                  <Field type="tel" className="form-control" name="phone" />
                  <ErrorMessage component={FormError} name="phone" />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="dob">Date of Birth</label>
                  <Field
                    type="date"
                    className="form-control"
                    name="dob"
                    placeholder="DOB"
                    max={dobLimit()}
                  />
                  <ErrorMessage component={FormError} name="dob" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="DLState">State</label>
                  <Field name="DLState" className="custom-select" as="select">
                    <option value="">Select...</option>
                    {renderStates()}
                  </Field>
                  <ErrorMessage component={FormError} name="DLState" />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="DLNumber">Driver's License #</label>
                  <Field
                    className="form-control"
                    name="DLNumber"
                    placeholder="DL#"
                  />
                  <ErrorMessage component={FormError} name="DLNumber" />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="paid">Paid</label>
                  <Field name="paid" className="custom-select" as="select">
                    <option value="">Select...</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Field>
                  <ErrorMessage component={FormError} name="paid" />
                </div>
              </div>

              {values.paid === "No" && (
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="notPaidReason">Reason</label>
                    <Field
                      as="textarea"
                      className="form-control"
                      name="notPaidReason"
                    />
                    <ErrorMessage component={FormError} name="notPaidReason" />
                  </div>
                </div>
              )}

              <div className="form-row signatures">
                <div className="form-group col-md-4">
                  <label htmlFor="signatureName">Applicant Full Name</label>
                  <Field
                    className="form-control"
                    name="signatureName"
                    placeholder="Applicant Name"
                  />
                  <ErrorMessage component={FormError} name="signatureName" />
                </div>

                <div className="form-group col-md-6 col-lg-4">
                  <label htmlFor="agreeDate">Date</label>
                  <Field
                    type="date"
                    className="form-control"
                    name="agreeDate"
                  />
                  <ErrorMessage component={FormError} name="agreeDate" />
                </div>
              </div>
            </div>

            <div className="form-check">
              <Field
                name="initialAgreement"
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="initialAgreement">
                I have read this Agreement and I understand and fully agree to
                it.
              </label>
            </div>
            <Button
              type="primary"
              disabled={disableBtn}
              onClick={(e) => onSubmit(e)}
            >
              Submit
            </Button>
          </div>
          <Context />
        </Form>
      </Formik>
    </>
  );
};

const styles = css`
  & {
    margin: 60px;
    h3 {
      text-align: center;
      margin: 40px 0;
    }
    .form-container {
      margin-top: 50px;
    }
    button {
      margin-top: 20px;
    }

    .signatures {
      margin-top: 50px;
    }
  }
`;
export default MFCAssistDsiclaimerForm;
