import "../../../assets/css/singleApplication.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { unNestObject, formatPhone } from "../../utils/helpers";
import {
  Page,
  Text,
  Document,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import FieldGroup from "../../utils/FieldGroup";

const SingleApplicationPDF = ({ initialValues, formId }) => {
  const [dataRequest, setDataRequest] = useState({
    fetching: true,
    error: false,
  });
  const [data, setData] = useState(
    (initialValues && unNestObject(initialValues)) || {}
  );

  const { fetching, error } = dataRequest;

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_SERVICE_API}/api/survey/${formId}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        if (!data) {
          setDataRequest({ fetching: false, error: true });
        } else {
          setData(data);
          setDataRequest({ fetching: false, error: false });
        }
      })
      .catch((error) => {
        setDataRequest({ fetching: false, error: true });
      });
  }, []);

  const {
    id,
    fillingOutForSomeoneElse,
    whyFillingOutForSomeoneElse,
    firstName,
    lastName,
    age,
    address,
    address2,
    city,
    state,
    zip,
    phone,
    email,
    living,
    maritalStatus,
    householdNumber,
    householdMemberInfo,
    businessOwner,
    grossSalary,
  } = data;

  const renderHouseholdMemeber = (obj) => {
    const objLength = Object.keys(obj).length / 2;
    let objArr = [];
    for (let i = 1; i <= objLength; i++) {
      const item = [
        { name: `Household Member Gender`, value: obj[`householdGender${i}`] },
        { name: `Household Member Age`, value: obj[`householdAge${i}`] },
      ];

      objArr.push(item);
    }
    return objArr;
  };

  if (fetching) {
    return <Spin size="large" className="spinner" />;
  } else if (error) {
    return (
      <div className="fetchError">
        <Text>
          Unable to retrieve application data. Please try again at a later time!
        </Text>
      </div>
    );
  }

  return (
    <div className="application application-container">
      <PDFViewer width="100%" height="700px">
        <Document>
          <Page size="A4" style={styles.view}>
            <Text style={styles.title}>Be Known Survey</Text>

            <section>
              <Text break style={styles.subtitle}>
                Survey Information: {id}
              </Text>
              {fillingOutForSomeoneElse === "Yes" && (
                <FieldGroup
                  values={[
                    {
                      name: "Filled by someone else",
                      value: fillingOutForSomeoneElse,
                    },
                    {
                      name: "Reason:",
                      value: whyFillingOutForSomeoneElse,
                    },
                  ]}
                />
              )}
              <FieldGroup
                values={[
                  { name: "First Name", value: firstName },
                  { name: "Last Name", value: lastName },
                  { name: "Age", value: age },
                ]}
              />

              <FieldGroup
                values={[{ name: "Street Address", value: address }]}
              />

              <FieldGroup
                values={[
                  { name: "Suite, Apt, Unit.", value: address2 || "" },
                  { name: "City", value: city },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "State", value: state },
                  { name: "Zip", value: zip },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Phone", value: formatPhone(phone) },
                  { name: "Email", value: email },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Martial Status", value: maritalStatus },
                  { name: "Living Status", value: living },
                  { name: "Household size", value: householdNumber || 0 },
                ]}
              />

              {householdMemberInfo &&
                renderHouseholdMemeber(householdMemberInfo).map((item) =>
                  <FieldGroup values={item} />
                )}

              <FieldGroup
                values={[
                  { name: "Business Owner", value: businessOwner },
                  { name: "Gross Annual Income", value: grossSalary },
                ]}
              />
            </section>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

const styles = StyleSheet.create({ 
  view: {
    color: "rgb(80, 79, 79)",
    fontFamily: "Times-Roman",
    paddingBottom: "20px",
    paddingTop: "30px",
  },
  title: {
    fontSize: 20,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    margin: "20px 0",
    textAlign: "center",
  },
  text: {
    fontSize: 12,
    margin: "5px 0",
  },
  agreeSignatureView: {
    margin: "30px 40px",
  },
});
export default SingleApplicationPDF;
