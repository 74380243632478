import React, { useState, useEffect } from "react";

import { Spin, Button, Modal } from "antd";
import axios from "axios";
import { FormNotification } from "../../utils/Message";
import { css } from "emotion";

const Users = () => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState();
  const [formData, setFormData] = useState();

  useEffect(() => {
    requestUserData();
  }, []);

  const requestUserData = () => {
    const token = localStorage.getItem("token");
    setFetching(true);
    axios
      .get(`${process.env.REACT_APP_SERVICE_API}/api/admin/users`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        setData(data);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        FormNotification("Unable to retrieve data", "error");
      });
  };

  const addUser = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_SERVICE_API}/api/admin/create`, formData, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then(() => requestUserData())
      .catch((error) => {
        FormNotification("Unable to create user!", "error");
      });
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onDelete = (userId) => {
    const token = localStorage.getItem("token");

    axios
      .delete(
        `${process.env.REACT_APP_SERVICE_API}/api/admin/users/${
          userId.split("-")[2]
        }`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        requestUserData();
      })
      .catch((error) => {
        FormNotification("Unable to delete form!", "error");
      });
  };

  if (fetching) {
    return <Spin className="spinner" size="large" />;
  }

  return (
    <div className={styles}>
      <div className="users-list">
        {!data && (
          <div className="no-forms">
            <p>No user data available!</p>
          </div>
        )}
        {data && (
          <div className="item-container">
            <div className="row">
              <div className="row-header col">Username</div>
              {/* <div className="row-header col">ID</div> */}
              <div className="row-header col">Role</div>
              <div className="row-header col">Date Created</div>
              <div className="row-header col">Delete</div>
            </div>
            {data.map(({ username, id, role, createdAt }) => (
              <div className="row form-item" key={id}>
                <div className="col">{username}</div>
                {/* <div className="col">{id}</div> */}
                <div className="col">{role}</div>
                <div className="col">{createdAt.split("T")[0]}</div>
                <div className="col">
                  <Button
                    onClick={() =>
                      Modal.confirm({
                        icon: (
                          <li
                            className={`fa fa-exclamation-circle ${modalStyles}`}
                          >
                            <span>Confirm</span>
                          </li>
                        ),
                        content:
                          "The user will be deleted and you will not be able to undo this action. Are you sure you want to delete this user?",
                        okText: "Delete",
                        cancelText: "Cancel",
                        onOk: () => onDelete(id),
                        style: {
                          position: "relative",
                          top: "250px",
                        },
                      })
                    }
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                </div>
              </div>
            ))}

            <div className="add-user">
              <h3>Add User</h3>
              <div className="form">
                <div className="row">
                  <div className="form-group">
                    <label htmlFor="username"></label>
                    <input
                      type="text"
                      placeholder="username"
                      name="username"
                      onChange={onChange}
                    />
                    <label htmlFor="password"></label>
                    <input
                      type="password"
                      name="password"
                      placeholder="password"
                      onChange={onChange}
                    />
                    <label htmlFor="role"></label>
                    <input
                      type="text"
                      name="role"
                      placeholder="'user' or 'admin'"
                      onChange={onChange}
                    />
                    <Button onClick={(e) => addUser(e)}>Add User</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = css`
  & {
    margin: 40px auto;
    .users-list .row-header {
      font-weight: bold;
    }

    .users-list .form-item {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      margin: 20px 0;
      padding: 20px 0;
    }

    .add-user {
      margin-top: 30px;
      text-align: center;
    }
    .add-user .form-group {
      margin: 0 auto;
    }
    .form-group input {
      margin: 20px 20px 0 0;
    }
    .ant-btn {
      color: black;
    }
  }
`;

const modalStyles = css`
  & {
    font-size: 30px !important;
    span {
      margin-left: 10px;
      font-size: 24px !important;
    }
  }
`;
export default Users;
