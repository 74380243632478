import "../../../assets/css/singleApplication.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { unNestObject, formatPhone } from "../../utils/helpers";
import {
  Page,
  Text,
  Document,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import FieldGroup from "../../utils/FieldGroup";

const SingleApplicationPDF = ({ initialValues, formId }) => {
  const [dataRequest, setDataRequest] = useState({
    fetching: true,
    error: false,
  });

  const [data, setData] = useState(
    (initialValues && unNestObject(initialValues)) || {}
  );

  const { fetching, error } = dataRequest;

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_SERVICE_API}/api/volunteer/${formId}`, {
        headers: { authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        if (!data) {
          setDataRequest({ fetching: false, error: true });
        } else {
          setDataRequest({ fetching: false, error: false });
          setData(data);
        }
      })
      .catch((error) => {
        setDataRequest({ fetching: false, error: true });
      });
  }, []);

  const {
    id,
    firstName,
    lastName,
    dob,
    address,
    address2,
    city,
    state,
    zip,
    phone,
    email,
    skills,
    assistArea,
    signatureName,
    agreeDate,
  } = data;
  //REMOVE
  if (fetching) {
    return <Spin size="large" className="spinner" />;
  } else if (error) {
    return (
      <div className="fetchError">
        <Text>
          Unable to retrieve application data. Please try again at a later time!
        </Text>
      </div>
    );
  }

  return (
    <div className="application application-container">
      <PDFViewer width="100%" height="700px">
        <Document>
          <Page size="A4" style={styles.view}>
            <Text style={styles.title}>Volunteer Application</Text>

            <section>
              <Text break style={styles.subtitle}>
                Volunteer Information: {id}
              </Text>
              <FieldGroup values={[{ name: "First Name", value: firstName }]} />

              <FieldGroup values={[{ name: "Last Name", value: lastName }]} />

              <FieldGroup values={[{ name: "Date of Birth", value: dob }]} />
              <FieldGroup
                values={[{ name: "Street Address", value: address }]}
              />

              <FieldGroup
                values={[
                  { name: "Suite, Apt, Unit.", value: address2 || "" },
                  { name: "City", value: city },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "State", value: state },
                  { name: "Zip", value: zip },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Phone", value: formatPhone(phone) },
                  { name: "Email", value: email },
                ]}
              />
              <FieldGroup values={[{ name: "skills", value: skills }]} />
              <FieldGroup
                values={[{ name: "Area of assistance", value: assistArea }]}
              />
            </section>
          </Page>

          <Page size="A4" style={styles.view}>
            <View style={styles.agreePage}>
              <View style={styles.agreeTextView}>
                <Text style={styles.title}>Volunteer Code of Conduct</Text>
                <View style={styles.agreeView}>
                  <Text style={styles.subtitle}>Introduction</Text>

                  <Text style={styles.agreeText}>
                    Muslims For Change, through its work, its supporters,
                    employees and volunteers wishes to be a role model of good
                    conduct and excellence in the community and the sector.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.subtitle}>Purpose</Text>

                  <Text style={styles.agreeText}>
                    By complying with the Code of Conduct, MFC can ensure that
                    the highest standard of conduct is consistently maintained
                    by its volunteers.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.subtitle}>Principles</Text>

                  <Text style={styles.agreeText}>
                    MFC expects volunteers to understand and comply with its
                    various Policies and Procedures, Code of Conduct and with
                    U.S. laws.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.subtitle}>Respect</Text>

                  <Text style={styles.agreeText}>
                    To always treat fellow volunteers, staff and supporters with
                    respect and dignity.
                  </Text>
                  <Text style={styles.agreeText}>
                    To be mindful and respect the views and beliefs of others.
                  </Text>
                  <Text style={styles.agreeText}>
                    To follow reasonable instructions from your supervisor.
                  </Text>
                  <Text style={styles.agreeText}>
                    To ensure personal appearance and hygiene levels are well
                    maintained.
                  </Text>
                  <Text style={styles.agreeText}>
                    To dress modestly and be mindful of your conduct in gender
                    interaction.
                  </Text>
                  <Text style={styles.agreeText}>
                    Not to engage in any dishonest, fraudulent or criminal
                    activities.
                  </Text>
                  <Text style={styles.agreeText}>
                    Not to behave in a way that is deemed to be offensive,
                    intimidating humiliating or threatened.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text break style={styles.subtitle}>
                    Fairness
                  </Text>

                  <Text style={styles.agreeText}>
                    To report any unlawful actions by fellow volunteers, staff
                    and supporters.
                  </Text>
                  <Text style={styles.agreeText}>
                    To be righteous and truthful at all times.
                  </Text>
                  <Text style={styles.agreeText}>
                    To treat people fair and equal and not to discriminate.
                  </Text>
                  <Text style={styles.agreeText}>
                    Not to be bias and unjust in your conduct.
                  </Text>
                  <Text style={styles.agreeText}>
                    Not to misuse MFC’s property or use it for personal gains.
                  </Text>
                  <Text style={styles.agreeText}>
                    Not to allow other business to conflict with your role.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.subtitle}>Leadership</Text>
                  <Text style={styles.agreeText}>
                    To act as a role model, have a positive attitude and always
                    lead by example.
                  </Text>
                  <Text style={styles.agreeText}>
                    To honour your commitment and carry out roles to the best of
                    your ability.
                  </Text>
                  <Text style={styles.agreeText}>
                    To perform your duties with honesty, integrity and with
                    professionalism.
                  </Text>
                  <Text style={styles.agreeText}>
                    To show appropriate behaviour towards fellow volunteers,
                    staff and supporters.
                  </Text>
                  <Text style={styles.agreeText}>
                    To share ideas, experiences and skills.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.subtitle}>Protection</Text>
                  <Text style={styles.agreeText}>
                    To safeguard the reputation of Muslims For Change at all
                    times
                  </Text>
                  <Text style={styles.agreeText}>
                    To perform roles responsibly and maintain a safe and secure
                    working environment.
                  </Text>
                  <Text style={styles.agreeText}>
                    To honour and abide by MFC’s policies.
                  </Text>
                  <Text style={styles.agreeText}>
                    To maintain high level of confidentiality at all times.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.subtitle}>Explained</Text>
                  <Text style={styles.agreeText}>
                    Everybody has equal worth as a human being and therefore
                    volunteers should allow everyone to maintain their dignity
                    and treat people with respect. Volunteers should always
                    conduct themselves in a way in which they would like to be
                    treated.
                  </Text>
                  <Text style={styles.agreeText}>
                    Volunteers should be mindful about the different beliefs of
                    individuals and groups. Belief may reflect numerous areas
                    and therefore you should always be open and respectful.
                    Volunteers should avoid forcing their opinion or beliefs
                    upon others.
                  </Text>
                  <Text style={styles.agreeText}>
                    Our staff and volunteers are the backbone of the
                    organisation. In order to maintain efficiency and
                    productivity as well as a good working environment, MFC
                    requests volunteers to liaise with and observe instructions
                    from their supervisor(s).
                  </Text>
                  <Text style={styles.agreeText}>
                    Failure to keep up a standard of hygiene can have many
                    implications. Not only is there an increased risk of getting
                    an infection or illness, but there are many social and
                    psychological aspects that can be affected. Being
                    presentable is a reflection of personality, professionalism
                    and an outlook of oneself.
                  </Text>
                  <Text style={styles.agreeText}>
                    When deciding the level of ‘modesty’ volunteers should be
                    aware about the environment and surrounding they are in.
                    Volunteers should be mindful and respectful on the different
                    views and practices of gender segregation.
                  </Text>
                  <Text style={styles.agreeText}>
                    Volunteers must not conduct themselves in a way that
                    undermines MFC morals and values such as dishonesty and
                    fraudulent acts. MFC is a politically neutral organisation,
                    therefore volunteers should not engage in any negative
                    political or threatening activities that may be detrimental
                    to Muslims For Change.
                  </Text>
                  <Text style={styles.agreeText}>
                    MFC aims to allow all its staff and volunteers to flourish
                    in a safe working and learning environment without the fear
                    of being intimidated, bullied and harassed. Everyone in the
                    organisation including volunteers has therefore the
                    responsibility to ensure that their actions, intentionally
                    or unintentionally, does not constitute or harassment or ill
                    treatment.
                  </Text>
                  <Text style={styles.agreeText}>
                    MFC expects volunteers to follow reasonable instructions and
                    guidelines, any breaches of organisation policies and
                    unlawful acts should be reported regardless of it being from
                    an employee, a supporter or a fellow volunteer.
                  </Text>
                  <Text style={styles.agreeText}>
                    Being trustworthy and truthful is one of the best traits to
                    possess. It is a positive reflection of oneself. Being
                    truthful means being righteous.
                  </Text>
                  <Text style={styles.agreeText}>
                    MFC expects volunteers to conduct themselves in the best
                    manner possible. Volunteers should always practice fairness
                    and be mindful, and respect people’s views, background,
                    gender, age, ability and beliefs.
                  </Text>
                  <Text style={styles.agreeText}>
                    It is important that decisions are made on the basis of
                    avoiding external and internal scrutiny and to also maintain
                    and uphold MFC’s values. Volunteers should never be bias or
                    unjust in their conduct and dealings.
                  </Text>
                  <Text style={styles.agreeText}>
                    MFC receives funds from public supporters and private sector
                    organisations; we therefore use these funds to buy equipment
                    and other resources. Volunteers should not misuse MFC’s
                    property nor use it for personal gains as the resources and
                    facilities are very limited.
                  </Text>
                  <Text style={styles.agreeText}>
                    MFC’s mission and values should not be compromised by ‘other
                    business’ interests volunteers may have. MFC requests
                    volunteers to be fully committed to their time with MFC and
                    to separate personal interests when engaging.
                  </Text>
                  <Text style={styles.agreeText}>
                    The community, MFC employees, volunteers and supporters
                    should look up to volunteers as a good example. Volunteers
                    should always thrive to maintain good standards of conduct
                    at all times.
                  </Text>
                  <Text style={styles.agreeText}>
                    MFC asks volunteers to demonstrate commitment when engaging.
                    Volunteers are the driving force behind MFC and therefore
                    they are working towards uplifting the Muslim community.
                  </Text>
                  <Text style={styles.agreeText}>
                    MFC seeks to ensure that the work environment for its
                    employees and volunteers is supportive and one where respect
                    is shown amongst peers, to its supporters and the community.
                    Volunteers should always uphold integrity and always have a
                    professional approach in their dealings.
                  </Text>
                  <Text style={styles.agreeText}>
                    MFC aims to sustain a healthy relationship with individuals
                    both within and outside of the organisation. MFC therefore
                    expects staff and volunteers to behave in a professional
                    manner within the working environment.
                  </Text>
                  <Text style={styles.agreeText}>
                    MFC takes pride in working with volunteers from all
                    backgrounds, experiences and professions. MFC encourages
                    volunteers to work together and where appropriate share
                    relevant experiences and skills.
                  </Text>
                  <Text style={styles.agreeText}>
                    Volunteers should always be mindful in their conduct and
                    always protect the reputation of MFC, its staff and
                    volunteers, employees and supporters.
                  </Text>
                  <Text style={styles.agreeText}>
                    MFC requires its volunteers to perform their
                    responsibilities adequately and safely to maintain
                    consistency and efficiency.MFC is liable in maintaining a
                    working environment where all activities can be carried out
                    with the highest consideration for the health and safety of
                    both volunteers and staff members.
                  </Text>
                  <Text style={styles.agreeText}>
                    The highest form of standards of behaviour is expected in
                    all areas of the organisation’s life. MFC expects volunteers
                    to maintain and sustain MFC's policies and U.S.laws.
                  </Text>
                  <Text style={styles.agreeText}>
                    Although MFC operates in an open fashion, there may be times
                    where a volunteer acquires or becomes aware of confidential
                    information whether it is about another volunteer, staff
                    member or the organisation. Volunteers must be aware of the
                    need to keep such matters confidential.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text break style={styles.subtitle}>
                    Policy on Political Activities &amp; Terrorism
                  </Text>
                  <Text style={styles.agreeText}>
                    Muslims For Change is a 501(c)3 non-profit charitable
                    organisation and, as such, it is prohibited by its corporate
                    character to participate in any type of political activities
                    or in any non-charitable functions which may conflict with
                    its obligation under Internal Revenue Code. These
                    prohibitions apply equally to all persons who work for and
                    or represent Muslims For Change in any capacity.
                  </Text>
                  <Text style={styles.agreeText}>
                    In addition, MFC volunteers should not (unless authorised in
                    writing) render an opinion or make a statement to the media
                    or in any social gathering, unless he/she prefaces that such
                    statements or opinions are solely his/hers and they do not
                    reflect the opinions or position of Muslims For Change.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.agreeText}>
                    I agree to the following policy:
                  </Text>
                  <Text style={styles.agreeText}>
                    As a volunteer of Muslims For Change, I will not engage
                    myself in any actions or activities which may be detrimental
                    to the organisation’s continued good standing within the
                    legal/social community at large.
                  </Text>
                  <Text style={styles.agreeText}>
                    I agree to refrain from any and all activities which may
                    conflict with Muslims For Change’s obligations, purpose and
                    goals. Furthermore, I agree in the event that I may be
                    called upon to render an opinion or make statements to the
                    media or in any social gathering, to preface that such
                    statements or opinions are solely mine and that they do not
                    reflect the opinions or position of Muslims For Change.
                  </Text>
                  <Text style={styles.agreeText}>
                    I irrevocably covenant, promise and agree to indemnify
                    Muslims For Change and hold it harmless from and against any
                    and all losses, claims, expenses, demands, damages, or
                    liabilities, of whatever kind or nature which Muslims For
                    Change may sustain or to which it may become subject arising
                    out of or relating in any way to my participation in any
                    activities in conflict with Muslims For Change’s obligation
                    under Internal Revenue Code, including in defending against
                    or enforcing any such losses, claims, expenses, suits,
                    damages, or liabilities.
                  </Text>
                  <Text style={styles.agreeText}>
                    This document is executed by me knowingly, intelligently and
                    voluntary.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.subtitle}>Confidentiality</Text>
                  <Text style={styles.agreeText}>
                    The Volunteer shall not make use of, divulge or communicate
                    to any person any trade secrets or other confidential
                    information of or relating to Muslims For Change, or that of
                    other persons or bodies with whom MFC has dealings of any
                    sort, which the ‘Volunteer’ may have received or obtained,
                    or has otherwise been acquired by them in confidence, whilst
                    engaging for MFC.
                  </Text>

                  <Text style={styles.agreeText}>
                    All material, whether held on paper, electronically or
                    magnetically which was made or acquired by the ‘Volunteer’
                    during the course of engagement, belongs to Muslims For
                    Change and are not to be shared with any person without the
                    permission of MFC.
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.subtitle}>Raising Concerns</Text>
                  <Text style={styles.agreeText}>
                    For further information or to raise concerns please email
                    info@msulimsforchange.org
                  </Text>
                </View>

                <View style={styles.agreeView}>
                  <Text style={styles.agreeText}>
                    The document outlines reasonable expectations of volunteers
                    and is not legally binding nor is it a contract of
                    employment, so therefore the applicant has the right to
                    refuse.
                  </Text>
                </View>

                <View style={styles.agreeView}></View>

                <View style={styles.agreeView}></View>

                <View style={styles.agreeView}></View>
              </View>

              <View style={styles.agreeSignatureView}>
                <Text break style={styles.subtitle}>
                  Signatures
                </Text>
                <FieldGroup
                  values={[
                    { name: "Applicant Full Name:", value: signatureName },
                    { name: "Date", value: agreeDate },
                  ]}
                />
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

const styles = StyleSheet.create({
  view: {
    color: "rgb(80, 79, 79)",
    fontFamily: "Times-Roman",
    paddingBottom: "20px",
    paddingTop: "30px",
  },
  title: {
    fontSize: 20,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    margin: "0 0 20px 0",
    textAlign: "center",
  },
  agreePage: {
    fontFamily: "Times-Roman",
    color: "rgb(80, 79, 79)",
  },
  agreeText: {
    fontSize: 12,
    margin: "3px 0",
  },
  agreeSignatureView: {
    margin: "30px 40px",
  },
});
export default SingleApplicationPDF;
