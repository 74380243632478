import React, { useEffect } from "react";
import { Form, Field, Formik, useFormikContext } from "formik";
// import Debugger from "../../utils/Debugger";
import { css } from "emotion";

const BeKnownExceptions = ({ disableBtn, initialValues, setInitialValues }) => {
  const onChange = (e) => {
    setInitialValues({
      ...initialValues,
      [e.target.name]: e.target.checked,
    });
  };

  const Context = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      !values.initialAgreement ? disableBtn(true) : disableBtn(false);
    }, [values]);
    return null;
  };

  return (
    <>
      {/* <Debugger data={initialValues} /> */}
      <Formik initialValues={initialValues}>
        <Form onChange={onChange}>
          <div className={styles}>
            <div className="main-content">
              <h5>Surveyee Criteria</h5>
              <ol>
                <li>The applicant must be Muslim.</li>
                <li>Is at least eighteen (18) years of age.</li>
                <li>Is a U.S. citizen or legal resident.</li>
              </ol>
              <h5>Introduction</h5>
              <p>
                Be known and be accounted for. Our organization is trying to be
                the backbone of the Muslim community in the United States and be
                present whenever the community needs us in the future. By
                collecting your household's information we can have an accurate
                understanding and representation of the community as a whole.
                That would also allow us to serve the community better because
                everything we do will have accurate data that backs it up.
              </p>
              <p>
                This survey is for Muslims living in the U.S regardless of their
                status or utilization of our services because the information we
                collect allow us to plan for future projects that will
                ultimately benefit everyone, rich or the poor.
              </p>

              <h5>Projects such as:</h5>
              <ol>
                <li>
                  Covering the Cost of Burial Services for your loved ones
                </li>
                <li>Adult and Youth Training and Support</li>
                <li>Collective Assistance to Foster Economic Development</li>
                <li>Capacity Building to Support Interest-Free Loans</li>
                <li>And much, much more</li>
              </ol>

              <p>
                This survey can play an essential role in helping us understand
                and address the needs of the community and by you participating
                you can make a difference.
              </p>

              <p>
                Spread the word and let every Muslim in the United States, all
                3.5 million of them be known.
              </p>

              <h5>Confidentiality</h5>
              <p>
                We use your the information you provided in this confidential
                survey to create statistics from the tables that contain all the
                data. Individual records are not shared with anyone, including
                federal agencies and law enforcement entities. All information
                you provide is strictly confidential and can only be accessed by
                MFC.
              </p>
            </div>

            <div className="form-check">
              <Field
                name="initialAgreement"
                type="checkbox"
                className="form-check-input"
              />
              <label htmlFor="initialAgreement">
                I have reviewed and understand the criteria for lending MFC.
              </label>
            </div>
          </div>
          <Context />
        </Form>
      </Formik>
    </>
  );
};

const styles = css`
  & {
    .main-content {
      margin-bottom: 50px;
      font-weight: 500;
    }
    label {
      font-weight: 500;
    }
    ol {
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 20px;
      font-size: 15px;
    }
    li {
      font-size: 15px;
    }
  }
`;

export default BeKnownExceptions;
