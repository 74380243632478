import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  DashboardView,
  ApplicationPDFRendererView,
  FormListView,
  UserListView,
  MFCAssistDsiclaimerFormView
} from "./views/AdminViews";
import { Message } from "../utils/Message";
import PrivateRoutes from "../utils/PrivateRoutes";
import Login from "../utils/Login";

function AdminApp() {
  useEffect(() => {
    const admin = process.env.REACT_APP_MODE === 'admin'
    if(admin) {
      const meta1 = document.createElement('meta');
      const meta2 = document.createElement('meta');
      meta1.name = 'robots';
      meta1.content = 'noindex';
      meta2.name = 'googlebot';
      meta2.content = 'noindex';

      const head = document.head
      head.appendChild(meta1)
      head.appendChild(meta2)
    }
  }, [])
  
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />

        {/* ADMIN ROUTES*/}
        <PrivateRoutes exact path="/" component={DashboardView} />
        <PrivateRoutes exact path="/forms" component={FormListView} />
        <PrivateRoutes exact path="/users" component={UserListView} />
        <PrivateRoutes exact path="/administrative" component={MFCAssistDsiclaimerFormView} />
        <PrivateRoutes
          exact
          path="/pdf/applications/:id"
          component={ApplicationPDFRendererView}
        />

        {/* 404 ROUTE*/}
        <Route
          path="*"
          component={() => (
            <Message>
              <p>404 Page Not Found!</p>
            </Message>
          )}
        />
      </Switch>
    </Router>
  );
}

export default AdminApp;
