import "../../../assets/css/singleApplication.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { unNestObject, formatPhone } from "../../utils/helpers";
import {
  Page,
  Text,
  Document,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import FieldGroup from "../../utils/FieldGroup";

const MFCAssistDisclaimerPDF = ({ formId }) => {
  const [dataRequest, setDataRequest] = useState({
    fetching: true,
    error: false,
  });

  const [data, setData] = useState({});

  const { fetching, error } = dataRequest;

  useEffect(() => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${process.env.REACT_APP_SERVICE_API}/api/administrative/assist-disclaimer/${formId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      )
      .then(({ data }) => {
        if (!data) {
          setDataRequest({ fetching: false, error: true });
        } else {
          setDataRequest({ fetching: false, error: false });
          setData(data);
        }
      })
      .catch((error) => {
        setDataRequest({ fetching: false, error: true });
      });
  }, []);

  const {
    id,
    firstName,
    lastName,
    phone,
    assistedBy,
    assistanceType,
    otherAssistance,
    DLNumber,
    DLState,
    paid,
    notPaidReason,
    agreeDate,
    signatureName,
  } = data;

  if (fetching) {
    return <Spin size="large" className="spinner" />;
  } else if (error) {
    return (
      <div className="fetchError">
        <Text>
          Unable to retrieve disclaimer data. Please try again at a later time!
        </Text>
      </div>
    );
  }

  return (
    <div className="application application-container">
      <PDFViewer width="100%" height="700px">
        <Document>
          <Page size="A4" style={styles.view}>
            <Text style={styles.title}>Disclaimer Form - {id}</Text>
            <View style={styles.agreeSignatureView}>
              <Text style={styles.subtitle}>Disclaimer</Text>

              <Text style={styles.text}>
                MUSLIMS FOR CHANGE and any of it's employees or volunteers are
                not responsible for any false information provided by the
                individual signing this document. All MUSLIMS FOR CHANGE and all
                of it's employees and volunteers are only tasked with assisting
                the individual signing this document and are providing the
                information provided by the individual signing this document as
                instructed to do so verbally or through a physical document
                obtained from the individual signing this document.
              </Text>

              <Text style={styles.subtitle}>Agreement</Text>

              <Text style={styles.text}>
                I hereby certify that all information provided to MUSLIMS FOR
                CHANGE and any of it's employees or volunteers is true to the
                best of my knowledge. I also agree that MUSLIMS FOR CHANGE and
                any of it's employees or volunteers are to not be held
                accountable for any fraud or fradulant activity that results
                from the information provided by me.
              </Text>

              <Text break style={styles.subtitle}>
                Applicant Info
              </Text>
              <FieldGroup
                values={[
                  { name: "Assisted By", value: assistedBy },
                  { name: "Assistance Type", value: assistanceType },
                ]}
              />

              {assistanceType === "Other" && (
                <FieldGroup
                  values={[
                    { name: "Assistance Detailer", value: otherAssistance },
                  ]}
                />
              )}
              <FieldGroup
                values={[
                  { name: "First Name", value: firstName },
                  { name: "Last Name", value: lastName },
                  { name: "Phone", value: formatPhone(phone) || "N/A" },
                ]}
              />
              <FieldGroup
                values={[
                  { name: "Driver's License #", value: DLNumber },
                  { name: "state", value: DLState },
                ]}
              />

              <FieldGroup
                values={[
                  { name: "Paid", value: paid },
                  { name: "Reason", value: notPaidReason },
                ]}
              />
            </View>

            <View style={styles.agreeSignatureView}>
              <Text break style={styles.subtitle}>
                Signatures
              </Text>
              <FieldGroup
                values={[
                  { name: "Applicant Full Name:", value: signatureName },
                  { name: "Date", value: agreeDate },
                ]}
              />
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  );
};

const styles = StyleSheet.create({
  view: {
    color: "rgb(80, 79, 79)",
    fontFamily: "Times-Roman",
    paddingBottom: "20px",
    paddingTop: "30px",
  },
  title: {
    fontSize: 20,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    margin: "20px 0",
    textAlign: "center",
  },
  text: {
    fontSize: 12,
    margin: "5px 0",
  },
  agreeSignatureView: {
    margin: "30px 40px",
  },
  officialSignatureView: {
    margin: "600px 40px 0",
  },
});
export default MFCAssistDisclaimerPDF;
